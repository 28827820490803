import { Button, Modal } from 'react-bootstrap';

interface LocalProps {
    show: boolean;
    cancelAborted: () => void;
    cancelConfirmed: () => void;
    cancelClicked: () => void;
}

const ConfirmCancelModal = ({ show, cancelAborted, cancelConfirmed, cancelClicked }: LocalProps) => {
    return (
        <Modal show={show} onHide={cancelClicked} centered>
            <Modal.Body>
                <h5>You have pending changes. Are you sure you want to cancel and lose your changes?</h5>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={cancelAborted}>
                    No, let me keep editing
                </Button>
                <Button variant="danger" onClick={cancelConfirmed}>
                    Yes, cancel and revert my changes
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmCancelModal;
