import { useState } from 'react';
import useClientAccountType from '../../../hooks/useClientAccountType';
import { AccountType } from '../../../models';
import { api } from '../../../store/api';
import AccountSelect, { AccountSelectProps } from './AccountSelect';

const ClientSelect = ({ ...props }: AccountSelectProps) => {
    const accountType = useClientAccountType();

    return <>{accountType && <InternalClientSelect {...props} accountType={accountType} />}</>;
};

interface InternalProps extends AccountSelectProps {
    accountType: AccountType;
}

const InternalClientSelect = ({ accountType, selectedAccountId, ...props }: InternalProps) => {
    const [searchString, setSearchString] = useState('');
    const { data: accounts } = api.useClientSearchQuery({ accountType, search: searchString }, { skip: searchString.length < 2 });
    const { data: selected } = api.useGetPerformerQuery(selectedAccountId ?? 0, { skip: !selectedAccountId });

    const fullProps = {
        ...props,
        searchString,
        accountType,
        selectedAccountId,
        setSearchString,
        accounts,
        selected,
    };

    return <AccountSelect {...fullProps} />;
};

export default ClientSelect;
