import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './authConfig';
import './styles/app.scss';

export const msalInstance = new PublicClientApplication(msalConfig);
export const trySetActiveAccount = () => {
    const account = msalInstance.getActiveAccount();
    if (!account) {
        const allAccounts = msalInstance.getAllAccounts();
        if (allAccounts && allAccounts.length) {
            msalInstance.setActiveAccount(allAccounts[0]);
        }
    }
};

export const handleLogout = () => {
    msalInstance.logoutRedirect().catch((e) => {
        console.error(e);
    });
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    //<React.StrictMode>
    <MsalProvider instance={msalInstance}>
        <App />
    </MsalProvider>
    //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
