import { Button } from 'react-bootstrap';
import { faMask, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useAddAccountPopup from '../../../hooks/useAddAccountPopup';
import useActAccountType from '../../../hooks/useActAccountType';

const QuickAddLinksSection = () => {
    const accountType = useActAccountType();
    const [open, newActPopup] = useAddAccountPopup();

    return (
        <>
            <h3>Quick Add Menu</h3>
            {accountType && (
                <Button onClick={() => open(accountType)}>
                    <FontAwesomeIcon icon={faPlus} />
                    &nbsp;
                    <FontAwesomeIcon icon={faMask} />
                    &nbsp;
                    <span>Add New Act/Performer</span>
                </Button>
            )}

            {newActPopup}
        </>
    );
};

export default QuickAddLinksSection;
