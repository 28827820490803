import Page from '../app/Page';
import useAddAccountPopup from '../../hooks/useAddAccountPopup';
import { Button, Col, Row } from 'react-bootstrap';
import useAllActs from '../../hooks/useAllActs';
import useActAccountType from '../../hooks/useActAccountType';
import AccountsTable from '../shared/components/AccountsTable';

const ActsPage = () => {
    const accountType = useActAccountType();
    const acts = useAllActs();
    const [open, newActPopup] = useAddAccountPopup();

    return (
        <Page title="Acts/Performers">
            <Row>
                <Col className="mb-2">
                    <AccountsTable accounts={acts} path="acts" />
                    {newActPopup}
                </Col>
            </Row>
            <Row>
                <Col>
                    {accountType && (
                        <Button onClick={() => open(accountType)} color="primary">
                            Add New Act
                        </Button>
                    )}
                </Col>
            </Row>
        </Page>
    );
};

export default ActsPage;
