import { Line, Text, View } from '@react-pdf/renderer';
import { Booking, AccountModel } from '../../../../models';
import { Svg } from '../../../../patches/@react-pdf/renderer';
import { toDisplayString } from '../../../shared/components/AppDate';
import contractStyles from '../contractStyleSheet';

interface LocalProps {
    contract?: Booking;
    performers?: AccountModel[];
}

const PerformersSectionView = ({ contract, performers }: LocalProps) => {
    const sectionTitle = (contract?.performers.length ?? 0) > 1 ? 'Performers' : 'Performer';

    return (
        <View>
            <Text style={{ ...contractStyles.h2, marginTop: 0 }}>{sectionTitle}</Text>
            {contract?.performers.map((p) => {
                const performer = performers?.find((x) => x.id === p.actId);

                return (
                    <View key={p.id} style={{ marginBottom: 15 }}>
                        <Text style={{ ...contractStyles.h3, marginTop: 0 }}>{performer?.name}</Text>
                        <View style={{ flexDirection: 'row', marginTop: 5 }}>
                            <View style={{ width: '35%' }}>
                                <Text style={contractStyles.body}>Date/Times</Text>
                            </View>
                            <View style={{ width: '20%' }}>
                                <Text style={contractStyles.body}>Performers</Text>
                            </View>
                            <View style={{ width: '45%' }}>
                                <Text style={contractStyles.body}>Location</Text>
                            </View>
                        </View>
                        <View>
                            <Svg height={2}>
                                <Line x1="0" y1="0" x2="300" y2="0" strokeWidth={2} stroke="rgb(0,0,0)" />
                            </Svg>
                        </View>

                        {contract?.performances.map((perf, i) =>
                            perf.actId === performer?.id ? (
                                <View key={perf.id} style={{ flexDirection: 'row' }}>
                                    <View style={{ width: '40%' }}>
                                        <Text style={contractStyles.body}>
                                            {toDisplayString(perf.date, false, true)} {perf.performanceTime}
                                        </Text>
                                    </View>
                                    <View style={{ width: '15%' }}>
                                        <Text style={contractStyles.body}>{perf.numberOfPerformers}</Text>
                                    </View>
                                    <View style={{ width: '45%' }}>
                                        <Text style={contractStyles.body}>{perf.venueName}</Text>
                                    </View>
                                </View>
                            ) : (
                                <></>
                            )
                        )}
                    </View>
                );
            })}
        </View>
    );
};

export default PerformersSectionView;
