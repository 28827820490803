import { Button } from 'react-bootstrap';
import { AccountModel, ContactModel } from '../../../models';
import { contactMaintenanceActions } from '../../../store/contactMaintenanceSlice';
import { useAppDispatch } from '../../../hooks/rtk';
import { api } from '../../../store/api';
import DataTable, { TableColumn } from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { Fragment } from 'react';

interface LocalProps {
    entity: AccountModel;
}

const ContactListCard = ({ entity }: LocalProps) => {
    const dispatch = useAppDispatch();
    const { data: accountTypes } = api.useGetAllAccountTypesQuery();
    const accountType = accountTypes?.find((x) => x.id === entity.accountTypeId);

    const openEditWindow = (contact: ContactModel) => {
        if (accountType) {
            dispatch(contactMaintenanceActions.editContact({ entityId: entity.id, contact, accountType }));
        }
    };

    const onNewClicked = () => {
        if (accountType) {
            dispatch(contactMaintenanceActions.createContact({ accountId: entity.id, accountType }));
        }
    };

    const columns: TableColumn<ContactModel>[] = [
        {
            cell: (row) => (
                <Button variant="secondary" size="sm" onClick={() => openEditWindow(row)}>
                    <FontAwesomeIcon icon={faEdit} />
                </Button>
            ),
            maxWidth: '50px',
        },
        {
            name: 'Name',
            selector: (row) => `${row.lastName}, ${row.firstName}`,
        },
        {
            name: 'Title',
            selector: (row) => row.title,
        },
        {
            name: 'Phone',
            cell: (row) => (
                <>
                    {row.contactMethods
                        .filter((x) => x.contactMethodTypeId === 1)
                        .map((p) => (
                            <Fragment key={p.id}>
                                {p.value} ({p.name})
                                <br />
                            </Fragment>
                        ))}
                </>
            ),
        },
        {
            name: 'Email',
            cell: (row) => (
                <div className="text-nowrap">
                    {row.contactMethods
                        .filter((x) => x.contactMethodTypeId === 2)
                        .map((p) => (
                            <Fragment key={p.id}>
                                {p.value} ({p.name})
                                <br />
                            </Fragment>
                        ))}
                </div>
            ),
        },
    ];

    return (
        <DataTable
            pagination
            highlightOnHover
            striped
            columns={columns}
            data={entity.contacts}
            subHeader
            subHeaderComponent={
                <Button variant="primary" onClick={onNewClicked}>
                    Add Contact
                </Button>
            }
        />
    );
};

export default ContactListCard;
