import { Button, Form, Modal } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from './rtk';
import { api } from '../store/api';
import { AccountType } from '../models';

const useAddAccountPopup = (): [(accountType: AccountType) => void, React.ReactNode] => {
    const [createAct, createActResult] = api.useCreatePerformerMutation();
    const [createClient, createClientResult] = api.useCreateClientMutation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [name, _setName] = useState<string>('');
    const setName = (e: React.ChangeEvent<HTMLInputElement>) => _setName(e.target.value);
    const [isOpen, setIsOpen] = useState(false);
    const [accountType, setAccountType] = useState<AccountType>();

    const open = (accountType: AccountType) => {
        setAccountType(accountType);
        setIsOpen(true);
    };

    const save = () => {
        if (accountType?.isAct) {
            createAct(name);
            alert(`Added new act: ${name}.`);
        }

        if (accountType?.isClient) {
            createClient(name);
            alert(`Added new client: ${name}.`);
        }

        close();
    };

    useEffect(() => {
        if (accountType?.isAct) {
            if (createActResult.data && createActResult.data.id > 0) {
                const actId = createActResult.data.id;
                navigate(`../acts/${actId}`, { replace: true });
            }
        }
        if (accountType?.isClient) {
            if (createClientResult.data && createClientResult.data.id > 0) {
                const clientId = createClientResult.data.id;
                navigate(`../clients/${clientId}`, { replace: true });
            }
        }
    }, [dispatch, navigate, createActResult, createClientResult, accountType]);

    const close = () => {
        _setName('');
        setIsOpen(false);
    };

    return [
        open,
        <Modal show={isOpen}>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>New {accountType?.isAct ? 'Act' : 'Client'} Name</Form.Label>
                        <Form.Control type="text" placeholder="name" value={name} onChange={setName}></Form.Control>
                    </Form.Group>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={close} className="mx-2">
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={save}>
                            Save
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal.Body>
        </Modal>,
    ];
};

export default useAddAccountPopup;
