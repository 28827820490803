import { TrackingNoteModel } from '../../../../models/bookings';
import TrackingNote from './TrackingNote';

interface LocalProps {
    notes: TrackingNoteModel[];
}

const TrackingNoteList = ({ notes }: LocalProps) => {
    return (
        <div>
            {notes.map((x) => (
                <TrackingNote note={x} key={x.id} />
            ))}
        </div>
    );
};

export default TrackingNoteList;
