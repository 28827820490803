import { Booking } from '../../../../models';

interface LocalProps {
    booking?: Booking;
}

const AudienceInfo = ({ booking }: LocalProps) => {
    return (
        <div className="contract-terms-info">
            <table>
                <tbody>
                    <tr>
                        <td className="text-muted">
                            <small>Audience Age</small>
                        </td>
                        <td className="text-end">{booking?.audienceAge ?? '___'}</td>
                    </tr>
                    <tr>
                        <td className="text-muted">
                            <small>Audience Size</small>
                        </td>
                        <td className="text-end">{booking?.audienceSize ?? '___'}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default AudienceInfo;
