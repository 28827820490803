import { Line, Text, View } from '@react-pdf/renderer';
import { Svg } from '../../../patches/@react-pdf/renderer';

const SignatureSection = () => (
    <View style={{ padding: 10, marginRight: 10 }}>
        <View style={{ flexDirection: 'row', fontSize: 11 }}>
            <View style={{ width: '50%', marginLeft: 40 }}>
                <View style={{}}>
                    <Text style={{ margin: 5 }}>{'{{Signature_es_:signer1:signature}}'}</Text>
                    <Svg height={2}>
                        <Line x1="0" y1="0" x2="200" y2="0" strokeWidth={2} stroke="rgb(0,0,0)" />
                    </Svg>
                    <Text style={{ marginLeft: 5, marginRight: 5, marginBottom: 5, color: 'grey' }}>Class Act Agent or Representative</Text>
                    <Text style={{ margin: 5 }}>{'{{Signature_es_:signer1:date}}'}</Text>
                    <Svg height={2}>
                        <Line x1="0" y1="0" x2="200" y2="0" strokeWidth={2} stroke="rgb(0,0,0)" />
                    </Svg>
                    <Text style={{ marginLeft: 5, marginRight: 5, marginBottom: 5, color: 'grey' }}>Date</Text>
                </View>
            </View>
            <View style={{ width: '50%', marginLeft: 40 }}>
                <View style={{}}>
                    <Text style={{ margin: 5 }}>{'{{Signature_es_:signer2:signature}}'}</Text>
                    <Svg height={2}>
                        <Line x1="0" y1="0" x2="200" y2="0" strokeWidth={2} stroke="rgb(0,0,0)" />
                    </Svg>
                    <Text style={{ marginLeft: 5, marginRight: 5, marginBottom: 5, color: 'grey' }}>Authorized Sponsor</Text>
                    <Text style={{ margin: 5 }}>{'{{Signature_es_:signer2:date}}'}</Text>
                    <Svg height={2}>
                        <Line x1="0" y1="0" x2="200" y2="0" strokeWidth={2} stroke="rgb(0,0,0)" />
                    </Svg>
                    <Text style={{ marginLeft: 5, marginRight: 5, marginBottom: 5, color: 'grey' }}>Date</Text>
                </View>
            </View>
        </View>
    </View>
);

export default SignatureSection;
