import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Booking } from '../../../../models';
import AppDate from '../../../shared/components/AppDate';
import CurrencyDisplay from '../../../shared/components/CurrenyDisplay';

interface LocalProps {
    booking?: Booking;
}

const ContractReceivablesInfo = ({ booking }: LocalProps) => {
    return (
        <div className="contract-terms-info">
            <table>
                <tbody>
                    <tr>
                        <td className="text-muted">
                            <small>Deposit Due</small>
                        </td>
                        <td className="text-end">
                            <AppDate date={booking?.depositDueDate} />
                        </td>
                    </tr>
                    <tr>
                        <td className="text-muted">
                            <small>Deposit</small>
                        </td>
                        <td className="text-end">
                            <CurrencyDisplay value={booking?.depositAmount} />
                        </td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td className="text-muted">
                            <small>Total Billable</small>
                        </td>
                        <td className="text-end">
                            <CurrencyDisplay value={booking?.billableAmount} />
                        </td>
                    </tr>
                    <tr>
                        <td className="text-muted">
                            <small>Amount Paid</small>
                        </td>
                        <td className="text-end text-success">
                            <CurrencyDisplay value={booking?.paidAmount} />
                        </td>
                    </tr>
                    <tr className="border-top">
                        <td className="text-muted">
                            <small>Balance Due</small>
                        </td>
                        <td className="text-end">
                            <CurrencyDisplay value={booking?.paymentStatus?.totalDueAmount} />
                        </td>
                    </tr>
                    <tr>
                        <td className="text-muted pt-3">
                            <small>Contract Signed?</small>
                        </td>
                        <td className="text-end pt-3">
                            {booking &&
                                (booking.contractSignedDate ? (
                                    <FontAwesomeIcon icon={faCheckCircle} className="text-success" />
                                ) : (
                                    <div className="text-danger">No</div>
                                ))}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default ContractReceivablesInfo;
