import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

interface LocalProps {
    buttonText: string;
    message: string;
    onConfirm: () => void;
}

const DeleteButton = ({ message, onConfirm, buttonText }: LocalProps) => {
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);

    const close = () => setShowConfirmDialog(false);
    const open = () => setShowConfirmDialog(true);

    return (
        <>
            <Button variant="outline-danger" onClick={open}>
                {buttonText}
            </Button>
            <Modal show={showConfirmDialog} onHide={close} centered>
                <Modal.Body>
                    <h4>{message}</h4>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={close}>
                        No, Cancel
                    </Button>
                    <Button variant="danger" onClick={onConfirm}>
                        Yes, Delete It!
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default DeleteButton;
