import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

interface MicrosoftGraphUser {
    displayName: string;
    givenName: string;
    mail: string;
    mobilePhone: string;
    surname: string;
    userPrincipalName: string;
    id: string;
}

export interface CurrentUserState {
    user?: MicrosoftGraphUser;
}

const initialState: CurrentUserState = {};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<MicrosoftGraphUser>) => {
            state.user = action.payload;
        },
        reset: (state) => {
            state = {};
        },
    },
});

export const { setUser, reset } = userSlice.actions;
export const selectCurrentUserState = (state: RootState) => state.currentUserState;
export default userSlice.reducer;
