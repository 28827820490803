import { AccountType, ContactMethodType, BookingStatusType, TypeBase } from '../models';
import ClassActClientBase from './ClassActClientBase';

class TypesClient<T extends TypeBase> extends ClassActClientBase {
    controllerName: string;

    constructor(controllerName: string) {
        super();
        this.controllerName = controllerName;
    }

    getAll = async () => {
        const result = await this.fetchJson<T[]>(`api/${this.controllerName}`, 'GET');

        return result ?? [];
    };
}

const contractStatusTypesClient = new TypesClient<BookingStatusType>('bookingStatusTypes');
const accountTypesClient = new TypesClient<AccountType>('accountTypes');
const contactMethodTypesClient = new TypesClient<ContactMethodType>('contactMethodTypes');

export { contractStatusTypesClient, accountTypesClient, contactMethodTypesClient };
