import { Alert, Offcanvas } from 'react-bootstrap';

interface LocalProps {
    title: string;
    children?: React.ReactNode;
    isPendingChanges: boolean;
    show: boolean;
    onHide: () => void;
}

const EntityEditOffcanvas = ({ title, children, isPendingChanges, show, onHide }: LocalProps) => {
    return (
        <Offcanvas className="entity-edit-offcanvas" show={show} onHide={onHide} placement="end">
            <Offcanvas.Header closeButton>
                <h5 className="m-2">{title}</h5>
                <Alert className="m-0 p-1" hidden={!isPendingChanges} variant="warning">
                    Pending Changes
                </Alert>
                <div></div>
            </Offcanvas.Header>
            <Offcanvas.Body>{children}</Offcanvas.Body>
        </Offcanvas>
    );
};

export default EntityEditOffcanvas;
