import { useEffect, useState } from 'react';
import { AccountModel } from '../models';
import { api } from '../store/api';

const useAllClients = () => {
    const { data: accountTypes } = api.useGetAllAccountTypesQuery();
    const { data: accounts } = api.useGetAllClientsQuery();
    const [clients, setClients] = useState<AccountModel[]>([]);

    useEffect(() => {
        if (accountTypes?.length && accounts?.length) {
            const actType = accountTypes.find((x) => x.isClient);
            if (actType) {
                setClients(accounts.filter((x) => x.accountTypeId === actType.id));
            }
        }
    }, [accountTypes, accounts]);

    return clients;
};

export default useAllClients;
