import { faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { TrackingNoteModel } from '../../../../models/bookings';
import { api } from '../../../../store/api';

interface LocalProps {
    contractId: number;
}

const AddTrackingNote = ({ contractId }: LocalProps) => {
    const [noteString, _setNoteString] = useState<string>('');
    const setNoteString = (e: React.ChangeEvent<HTMLInputElement>) => _setNoteString(e.target.value);
    const [addTrackingNote] = api.useAddTrackingNoteMutation();

    const save = () => {
        const newTrackingNote: TrackingNoteModel = {
            id: 0,
            text: noteString,
        };
        addTrackingNote({ contractId, note: newTrackingNote });
        _setNoteString('');
    };

    return (
        <Form>
            <Form.Group>
                <div className="d-flex">
                    <Form.Label className="my-3">New Tracking Note</Form.Label>
                    <Button className="m-2" onClick={save}>
                        <FontAwesomeIcon icon={faSave} /> Save
                    </Button>
                </div>
                <Form.Control as="textarea" rows={3} value={noteString} onChange={setNoteString}></Form.Control>
            </Form.Group>
        </Form>
    );
};

export default AddTrackingNote;
