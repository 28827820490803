import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        fontSize: 8,
        color: '#454545',
    },
});

const TermsOfAgreement = () => (
    <>
        <View style={styles.row}>
            <Text>{`1. `}</Text>
            <Text>
                It is herein understood that the sponsor is solely liable for the conditions of the performance facility, including all accessories and personal
                property, conduct of the employees and audience and will hold harmless the performer, agent(s) and managers, from any and all liabilities and
                claims including attorney's fees arising therefrom
            </Text>
        </View>
        <View style={styles.row}>
            <Text>{`2. `}</Text>
            <Text>
                The agreement of the Artists to perform is subject to proven detention by sickness, accident, acts of God and other legitimate conditions.
            </Text>
        </View>
        <View style={styles.row}>
            <Text>{`3. `}</Text>
            <Text>
                Cancellation Policy: If the sponsors cancels this performance after a fully executed contract is in place 20% of the contracted price is due. In
                the event sponsor cancels this performance between 119 and 60 days of the performance date, sponsor shall be responsible for one-half of the
                contracted fee. If the sponsor cancels performance within sixty (60) days of performance date, all fees will be payable in full. Cancellation on
                the part of the artist will result in refund to sponsor of all deposits and any other preliminary fees by artist. In the case of an outdoor
                event, full payment is due regardless of cancellation due to weather conditions. It is further agreed that if cancellation occurs for any reason
                and performance is rescheduled at a mutually agreeable date, Contractee will be responsible for payment of all travel charges involved in a
                rescheduled date.
            </Text>
        </View>
        <View style={styles.row}>
            <Text>{`4. `}</Text>
            <Text>
                Late payment: Failure to comply with payment terms before and at the time of performance as stated in this contract will result in a penalty of
                $10.00 per day on any outstanding balance after the performance.
            </Text>
        </View>
        <View style={styles.row}>
            <Text>{`5. `}</Text>
            <Text>
                Any reproduction including and not limited to videotaping, audio recording and television broadcasting and transmission is solely prohibited
                except by written agreement with Class Act.
            </Text>
        </View>
        <View style={styles.row}>
            <Text>{`6. `}</Text>
            <Text>
                It is agreed that fee payable to Artist is inclusive of any State entertainment tax withholding, and that Class Act has been notified in advance
                of this agreement of any monies withheld. It is further agreed that Contractee will pay all fees withheld if they fail to notify Class Act in
                advance of this fully executed contract. It is further agreed that this Class Act contract will take precedence over any other contracts or
                agreements submitted regarding this date.
            </Text>
        </View>
        <View style={styles.row}>
            <Text>{`7. `}</Text>
            <Text>
                Rebooking: It is hereby noted that any subsequent inquiries and booking of the above-named act for a period of five (5) years from the date of
                this contract will be referred to and contracted through Class Act.
            </Text>
        </View>
        <View style={styles.row}>
            <Text>{`8. `}</Text>
            <Text>
                The foregoing plus any attached riders, including technical requirements, constitute the agreement between sponsor and Class Act. This agreement
                shall be expounded according to the laws of the State of Wisconsin.
            </Text>
        </View>
    </>
);

export default TermsOfAgreement;
