import { Card } from 'react-bootstrap';
import { TrackingNoteModel } from '../../../models/bookings';
import AddTrackingNote from './trackingNotes/AddTrackingNote';
import TrackingNoteList from './trackingNotes/TrackingNoteList';

interface LocalProps {
    contractId: number;
    notes: TrackingNoteModel[];
}

const ContractNotesSection = ({ contractId, notes }: LocalProps) => {
    return (
        <Card className="p-3">
            <AddTrackingNote contractId={contractId} />
            <TrackingNoteList notes={notes} />
        </Card>
    );
};

export default ContractNotesSection;
