import { Text, View } from '@react-pdf/renderer';
import { Booking } from '../../../../models';
import contractStyles from '../contractStyleSheet';

interface LocalProps {
    booking?: Booking;
}

interface Venue {
    name: string;
    address: string;
}

const VenueSectionView = ({ booking }: LocalProps) => {
    const distinctAddresses: Venue[] = [];
    booking?.performances.forEach((x) => {
        if (x?.address && !distinctAddresses.find((existing) => x.venueName === existing.name)) {
            distinctAddresses.push({ name: x.venueName, address: x.address.freeformAddress });
        }
    });

    return (
        <>
            <Text style={contractStyles.h2}>Location</Text>
            {distinctAddresses?.map((x) => (
                <View key={x.name}>
                    <Text style={contractStyles.body}>{x.name}</Text>
                    <Text style={contractStyles.body}>{x.address}</Text>
                </View>
            ))}
        </>
    );
};

export default VenueSectionView;
