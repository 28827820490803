import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { accountTypesClient } from '../../api';
import { AccountType } from '../../models';
import { RootState } from '../store';

const adapter = createEntityAdapter<AccountType>({
    selectId: (x) => x.id,
    sortComparer: (a, b) => a.name.localeCompare(b.name),
});

const getAll = createAsyncThunk('accountTypes/getall', async (thunkAPI) => {
    const response = await accountTypesClient.getAll();
    return response;
});

const slice = createSlice({
    name: 'accountTypes',
    initialState: adapter.getInitialState({
        isLoading: false,
    }),
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAll.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(getAll.fulfilled, (state, action) => {
            adapter.setAll(state, action.payload);
        });
    },
});

const selectors = adapter.getSelectors<RootState>((state) => state.accountTypes);
const accountTypesSelectors = { ...selectors };
const accountTypesActions = { ...slice.actions, getAll };

export default slice.reducer;
export { accountTypesSelectors, accountTypesActions };
