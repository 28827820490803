import { BookingClientEditModel } from '../../../models';
import { RootState } from '../../store';
import createEntityEditSlice, { EntityEditState, createIsPendingChangesSelector } from './createEntityEditSlice';

const slice = createEntityEditSlice({
    name: 'contractEditClient',
    initialState: { isEditing: false } as EntityEditState<BookingClientEditModel>,
    reducers: {},
});

const selectState = (state: RootState) => state.contractEditClient;
const selectIsPendingChanges = () => createIsPendingChangesSelector(selectState);

const contractClientEditActions = { ...slice.actions };
const contractClientEditSelectors = { selectIsPendingChanges };

export { contractClientEditActions, contractClientEditSelectors };
export default slice.reducer;
