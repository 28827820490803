import { useEffect, useState } from 'react';
import ReactSelect, { SingleValue } from 'react-select';
import { AddressModel, AzureMapsAddressResult } from '../../../models';
import { api } from '../../../store/api';
import LoadingOverlay from './LoadingOverlay';

interface LocalProps {
    selected?: AddressModel;
    onChange?: (account: AddressModel) => void;
    placeholder?: string;
}

interface AddressOption {
    value: string;
    label: string;
    address: Partial<AzureMapsAddressResult>;
    id?: number;
}

const toOption = (address: AzureMapsAddressResult): AddressOption => ({ address: address, value: address.id, label: address.address.freeformAddress ?? '' });

const AddressSelect = ({ selected, onChange, placeholder }: LocalProps) => {
    const [searchString, setSearchString] = useState('');
    const { data: addresses } = api.useAddressSerachQuery(searchString, { skip: searchString.length < 2 });
    const [saveAddress, { data, error, isLoading }] = api.useSaveAddressMutation();
    const [options, setOptions] = useState<AddressOption[]>([]);

    const _onChange = (option?: SingleValue<AddressOption>) => {
        if (option && onChange) {
            if (option.id && option.address.address?.freeformAddress) {
                onChange({ id: option.id, freeformAddress: option.address.address.freeformAddress });
            } else {
                saveAddress(option.address);
            }
        }
    };

    useEffect(() => {
        if (onChange && !isLoading && !error && data) {
            onChange(data);
        }
    }, [data, isLoading, error, onChange]);

    useEffect(() => {
        if (searchString.length >= 2) {
            if (addresses?.results.length) {
                const optionsTemp = addresses.results.map(toOption);
                setOptions(optionsTemp);
            }
        } else {
            setOptions([]);
            setSearchString('');
        }
    }, [addresses, searchString]);

    useEffect(() => {
        if (selected) {
            if (!options?.some((x) => x.value === selected?.id.toString())) {
                const address: Partial<AzureMapsAddressResult> = { id: selected.id.toString(), address: { freeformAddress: selected.freeformAddress } };
                const selectedOption: AddressOption = {
                    address,
                    value: selected.id.toString(),
                    label: selected.freeformAddress,
                    id: selected.id,
                };
                setOptions([...(options ?? []), selectedOption]);
            }
        }
    }, [selected, options]);

    const currentValue = options?.find((x) => x.value === selected?.id.toString());

    return (
        <>
            {isLoading && <LoadingOverlay />}
            <ReactSelect
                options={options}
                value={currentValue}
                onInputChange={(value) => setSearchString(value)}
                onChange={_onChange}
                placeholder={placeholder}
                filterOption={() => true}
            />
        </>
    );
};

export default AddressSelect;
