import { Col, Row } from 'react-bootstrap';
import { api } from '../../store/api';
import Page from '../app/Page';
import BookingSummaryTable from '../shared/components/BookingSummaryTable';
import QuickAddLinksSection from './components/QuickAddLinksSection';

const DashboardPage = () => {
    const { data: bookings, isLoading, isFetching } = api.useGetAllOpenBookingsQuery();

    return (
        <Page title="Dashboard">
            <Row>
                <Col>
                    <BookingSummaryTable data={bookings} isLoading={isLoading || isFetching} />
                </Col>
            </Row>
            <Row className="mt-2">
                <Col md={8}></Col>
                <Col md={4}>
                    <QuickAddLinksSection />
                </Col>
            </Row>
        </Page>
    );
};

export default DashboardPage;
