import { Text, View } from '@react-pdf/renderer';
import { Booking } from '../../../../models';
import { currencyFormatter } from '../../../shared';
import { toDisplayString } from '../../../shared/components/AppDate';
import contractStyles from '../contractStyleSheet';
import LabeledValueView from './LabeledValueView';

interface LocalProps {
    contract?: Booking;
}

const PaymentTermsSectionView = ({ contract }: LocalProps) => {
    return (
        <>
            <Text style={contractStyles.h2}>Payment Terms</Text>
            <LabeledValueView label="Total Cost" value={currencyFormatter.format(contract?.billableAmount ?? 0)} />
            <View style={{ marginTop: 5 }}>
                <LabeledValueView label="Deposit Amount" value={currencyFormatter.format(contract?.depositAmount ?? 0)} />
                <LabeledValueView label="Deposit Due" value={contract?.depositDueDate ? toDisplayString(contract?.depositDueDate, false, false) : ''} />
            </View>
        </>
    );
};

export default PaymentTermsSectionView;
