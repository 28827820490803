import { useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import DataTable, { TableColumn } from 'react-data-table-component';
import { BookingStatusType } from '../../../models';
import { api } from '../../../store/api';

const ContractStatusMaintCard = () => {
    const { data: contractStatuses } = api.useGetAllBookingStatusTypesQuery();

    const columns: TableColumn<BookingStatusType>[] = [
        {
            name: 'Status',
            selector: (row) => row.name,
        },
        {
            name: 'Color',
            selector: (row) => row.calendarColorHex,
            cell: (row) => <ColorPickerCell row={row} />,
        },
    ];

    return (
        <Card>
            <Card.Header>Contract Statuses</Card.Header>
            <Card.Body>
                <DataTable highlightOnHover columns={columns} data={contractStatuses ?? []} />
            </Card.Body>
        </Card>
    );
};

export default ContractStatusMaintCard;

interface ColorPickerCellProps {
    row: BookingStatusType;
}

const ColorPickerCell = ({ row }: ColorPickerCellProps) => {
    const [color, setColor] = useState<string>(row.calendarColorHex);
    const [update] = api.useUpdateContractStatusTypeMutation();

    return (
        <Form.Group className="d-flex align-items-center">
            <Form.Control
                type="color"
                id={`status-type-${row.id}-color-picker`}
                defaultValue={row.calendarColorHex}
                onChange={(x) => setColor(x.target.value)}
            />
            <div>
                {row.calendarColorHex !== color && (
                    <Button className="mx-2" size="sm" onClick={() => update({ ...row, calendarColorHex: color })}>
                        Save
                    </Button>
                )}
            </div>
        </Form.Group>
    );
};
