import React from 'react';
import { Button, Container } from 'react-bootstrap';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../../authConfig';
import { IPublicClientApplication } from '@azure/msal-browser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignIn } from '@fortawesome/free-solid-svg-icons';
import { trySetActiveAccount } from '../../..';

const handleLogin = (instance: IPublicClientApplication) => {
    instance
        .loginRedirect(loginRequest)
        .then(trySetActiveAccount)
        .catch((e) => console.error(e));
};

export const SignInButton = () => {
    const { instance } = useMsal();

    return (
        <Container fluid className="text-center">
            <Button variant="primary" onClick={() => handleLogin(instance)}>
                <FontAwesomeIcon icon={faSignIn} />
                &nbsp;Sign In
            </Button>
        </Container>
    );
};
