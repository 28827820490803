import ContactCard from './ContactCard';

interface LocalProps {
    contactId?: number;
}

const SiteContactCard = ({ contactId }: LocalProps) => {
    return (
        <div className="site-contact-card">
            <ContactCard title="Site Contact" contactId={contactId} />
        </div>
    );
};

export default SiteContactCard;
