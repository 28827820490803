import { Card, Col, Form, Row } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../../../hooks/rtk';
import { contractClientEditActions as actions } from '../../../../store/contracts';
import NumericFormControl from '../../../shared/components/NumericFormContorl';
import AppDatePicker from '../../../shared/components/AppDatePicker';

const EditClientBillingCard = () => {
    const dispatch = useAppDispatch();
    const workingCopy = useAppSelector((state) => state.contractEditClient.workingCopy);

    if (!workingCopy) return null;

    return (
        <Card>
            <Card.Header>Billing</Card.Header>
            <Card.Body>
                <Row>
                    <Col>
                        <Form.Label>Billable Amount</Form.Label>
                        <NumericFormControl
                            value={workingCopy.billableAmount}
                            onChange={(e) => dispatch(actions.updateWorkingCopy({ ...workingCopy, billableAmount: e, depositAmount: e * 0.2 }))}
                        />
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col>
                        <Form.Label>Deposit Amount</Form.Label>
                        <Form.Control
                            type="number"
                            value={workingCopy.depositAmount ?? ''}
                            onChange={(e) => dispatch(actions.updateWorkingCopy({ ...workingCopy, depositAmount: +e.target.value }))}
                        />
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col>
                        <Form.Label>Deposit Due</Form.Label>
                        <AppDatePicker
                            value={workingCopy.depositDueDate}
                            onChange={(date) => dispatch(actions.updateWorkingCopy({ ...workingCopy, depositDueDate: date }))}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Label className="text-success">Paid Amount</Form.Label>
                        <Form.Control
                            type="number"
                            value={workingCopy.paidAmount ?? ''}
                            onChange={(e) => dispatch(actions.updateWorkingCopy({ ...workingCopy, paidAmount: +e.target.value }))}
                        />
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

export default EditClientBillingCard;
