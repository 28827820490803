import { Accordion, Col, Form, Row } from 'react-bootstrap';
import { api } from '../../../store/api';

interface LocalProps {
    legacyBookingId: number;
}

const LegacyBookingCard = ({ legacyBookingId }: LocalProps) => {
    const { data: record } = api.useGetLegacyApproachRecordQuery(legacyBookingId);

    return (
        <>
            <Accordion.Header>
                {`${record?.serialNumber} (${legacyBookingId})`} : {record?.performer} ({record?.eventDate})
            </Accordion.Header>
            <Accordion.Body>
                <ItemRow
                    items={[
                        { label: 'ID', value: record?.id },
                        { label: 'Callback', value: record?.callback },
                        { label: 'Bkcd By', value: record?.takeN_BY },
                        { label: 'Call Type', value: record?.calL_TYPE },
                        { label: 'See Tracking', value: 'x' },
                        { label: 'SN', value: record?.serialNumber },
                    ]}
                />
                <ItemRow
                    items={[
                        { label: 'Today Date', value: record?.todayDate },
                        { label: 'Performer', value: record?.performer },
                        { label: 'Code', value: record?.code },
                        { label: 'Location of Program', value: 'x' },
                        { label: 'Client Red Flag', value: record?.clienT_RED },
                    ]}
                />
                <ItemRow
                    items={[
                        { label: 'Loc Address', value: 'x' },
                        { label: 'Loc City', value: 'x' },
                        { label: 'ST', value: 'x' },
                        { label: 'Loc Zip', value: 'x' },
                        { label: 'Loc Phone', value: record?.locationPhone },
                        { label: 'Act Red Flag', value: record?.acT_RED_FL },
                    ]}
                />
                <ItemRow
                    items={[
                        { label: 'Performance', value: record?.performance },
                        { label: 'CA B', value: record?.classActBook },
                        { label: 'Prfrmrs', value: record?.performers },
                        { label: 'Prfrmcs', value: record?.performances },
                        { label: 'Perf Descriptions', value: 'x' },
                        { label: 'International', value: record?.internatio },
                    ]}
                />
                <ItemRow
                    items={[
                        { label: 'Room', value: record?.rooM_ },
                        { label: 'Perf Length', value: record?.performanceLength },
                        { label: 'Event Date', value: record?.eventDate },
                        { label: 'Day', value: 'x' },
                        { label: 'Event Time', value: record?.eventTime },
                        { label: 'Check in', value: record?.checkInTime },
                    ]}
                />
                <ItemRow
                    items={[
                        { label: 'Group Size', value: record?.groupSize },
                        { label: 'Age', value: record?.age },
                        { label: 'Booking Contact', value: record?.contact },
                        { label: 'Title', value: record?.contactTitle },
                    ]}
                />
                <ItemRow
                    items={[
                        { label: 'Facility', value: record?.facility },
                        { label: 'Address', value: record?.contactAddress },
                        { label: 'City', value: record?.contactCity },
                        { label: 'State', value: record?.contactState },
                        { label: 'Map From', value: record?.mapFrom },
                    ]}
                />
                <ItemRow
                    items={[
                        { label: 'Zip', value: record?.contactZip },
                        { label: 'Zip+4', value: record?.z_4 },
                        { label: 'Work Phone', value: record?.contactWorkPhone },
                        { label: 'Fax', value: 'x' },
                        { label: 'Home Phone', value: record?.contactHomePhone },
                        { label: 'Boces', value: 'x' },
                        { label: 'Label', value: record?.label },
                    ]}
                />
                <ItemRow
                    items={[
                        { label: 'Fee', value: record?.fee },
                        { label: 'CA %', value: record?.cA_ },
                        { label: 'CA Fee', value: 'x' },
                        { label: 'Deposit', value: record?.deposit },
                        { label: 'Balance', value: 'x' },
                        { label: 'Payee', value: record?.payee },
                        { label: 'Class Act Commiss', value: 'x' },
                        { label: 'Heard', value: record?.heard },
                    ]}
                />
                <ItemRow
                    items={[
                        { label: 'Pay To', value: record?.payto },
                        { label: 'Due Date', value: record?.dueDate },
                        { label: 'Terms', value: record?.terms },
                    ]}
                />
                <ItemRow
                    items={[
                        { label: 'Site Contact', value: record?.sitecontac },
                        { label: 'Event Type', value: record?.eventype },
                        { label: 'Added Date', value: record?.addedate },
                        { label: 'Email Address', value: record?.emaiL_ADDR },
                    ]}
                />
                <ItemRow
                    items={[
                        { label: 'Web Site', value: record?.weB_SITE },
                        { label: 'Source', value: 'x' },
                    ]}
                />
                <Row>
                    <Item label="Tracking Notes" value={record?.trackinG_N} />
                </Row>
                memo-{record?.memo}
                <br />
                quote-{record?.quote}
                <br />
                act1-{record?.act1}
                <br />
                act2-{record?.act2}
                <br />
                act3-{record?.act3}
                <br />
                requestedDate-{record?.requestedDate}
                <br />
                infoSent-{record?.infoSent}
                <br />
                _THER-{record?._THER}
                <br />
                creturned-{record?.creturned}
                <br />
                deprtnd-{record?.deprtnd}
                <br />
                dress-{record?.dress}
                <br />
                {record?.source}
                <br />
                {record?.flwuP1}
                <br />
                {record?.x}
                <br />
                {record?.fax}
                <br />
                {record?.arrivettM9}
                <br />
                {record?.ziP__PC}
                <br />
                {record?.current}
                <br />
                {record?.seE_TRACKI}
                <br />
                {record?.direcT_S_R}
                <br />
                {record?.perF__CALL}
                <br />
                {record?.perF__OK_D}
                <br />
                {record?.caL__UPDAT}
                <br />
                {record?.contracT_M}
                <br />
                {record?.foR_OFFICE}
                <br />
                booked-{record?.booked}
                <br />
                {record?.odD_AMT}
                <br />
                {record?.sort}
                <br />
                {record?.commission}
                <br />
                finaL_BALA-{record?.finaL_BALA}
                <br />
                {record?.feE_PAYABL}
                <br />
                feE_TO_BE_-{record?.feE_TO_BE_}
                <br />
                performeR_-{record?.performeR_}
                <br />
                {record?.signature}
                <br />
                {record?.signaturE_}
                <br />
                {record?.peR_CONT_C}
                <br />
                clasS_ACT_-{record?.clasS_ACT_}
                <br />
                {record?.boces}
                <br />
                {record?.clasS_ACT2}
                <br />
                {record?.cA_CHECK__}
                <br />
                {record?.cA_CHECK_A}
                <br />
                {record?.cA_CHECK_M}
                <br />
                {record?.performeR2}
                <br />
                {record?.additional}
                <br />
                {record?.calL_IF_IN}
                <br />
                {record?.performeR3}
                <br />
                {record?.dates}
                <br />
                {record?.tO_BE_DISP}
            </Accordion.Body>
        </>
    );
};

export default LegacyBookingCard;

interface ItemProps {
    label: string;
    value: string | number | undefined;
}

const Item = ({ label, value }: ItemProps) => {
    return (
        <Form.Group className="mb-3">
            <Form.Label>{label}</Form.Label>
            <Form.Control type="text" disabled placeholder={value?.toString()} />
        </Form.Group>
    );
};

interface ItemRowProps {
    items: ItemProps[];
}

const ItemRow = ({ items }: ItemRowProps) => {
    return (
        <Row>
            {items.map((x) => (
                <Col key={x.label}>
                    <Item {...x} />
                </Col>
            ))}
        </Row>
    );
};
