import { createRef, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { AddressModel } from '../../../models';
import { api } from '../../../store/api';
import AddressSelect from './AddressSelect';

interface LocalProps {
    accountId: number;
    show: boolean;
    onHide: () => void;
}

const AddAccountAddressModel = ({ accountId, show, onHide }: LocalProps) => {
    const nameRef = createRef<HTMLInputElement>();
    const [addNew] = api.useAddAccountAddressMutation();
    const [selected, setSelected] = useState<AddressModel>();

    const addAddress = () => {
        if (nameRef.current?.value && selected?.id) {
            addNew({ accountId, addressId: selected?.id, name: nameRef.current.value });
            onHide();
        }
    };

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header>Add Address</Modal.Header>
            <Modal.Body>
                <Form.Group className="mb-3">
                    <Form.Label>Address Name</Form.Label>
                    <Form.Control type="text" ref={nameRef} />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Address Search</Form.Label>
                    <AddressSelect onChange={setSelected} />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button size="sm" onClick={addAddress}>
                    Add
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddAccountAddressModel;
