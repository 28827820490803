import { Text, View } from '@react-pdf/renderer';
import { Booking } from '../../../../models';
import { toDisplayString } from '../../../shared/components/AppDate';
import contractStyles from '../contractStyleSheet';

interface LocalProps {
    booking?: Booking;
}

const PageHeaderView = ({ booking }: LocalProps) => {
    return (
        <View style={{ margin: 5, fontSize: 10, flexDirection: 'column', alignItems: 'flex-end' }}>
            <View style={{ flexDirection: 'row' }}>
                <Text style={contractStyles.label}>Booking # : </Text>
                <Text style={{}}>{booking?.id}</Text>
            </View>
            <Text style={{}}>{booking?.contractDate && toDisplayString(booking?.contractDate, false, false)}</Text>
        </View>
    );
};

export default PageHeaderView;
