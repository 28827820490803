import { Form } from 'react-bootstrap';
import { api } from '../../../../store/api';
import { useMemo, useState } from 'react';
import { debounce } from 'lodash';

interface LocalProps {
    accountId: number;
    name: string;
}

const EditableClientNameHeader = ({ accountId, name }: LocalProps) => {
    const [updateName] = api.useUpdateAccountNameMutation();
    const [value, setValue] = useState(name);

    const debouncedUpdate = useMemo(() => debounce((v: string) => updateName({ accountId, name: v }), 500), [accountId, updateName]);

    const onChange = (v: string) => {
        setValue(v);
        debouncedUpdate(v);
    };

    return <Form.Control type="text" value={value} onChange={(e) => onChange(e.target.value)} className="client-name-haeder" />;
};

export default EditableClientNameHeader;
