import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useParams } from 'react-router-dom';
import { api } from '../../store/api';
import Page from '../app/Page';
import ContactListTable from './components/ContactListTable';

const ActDetailPage = () => {
    const { id = '0' } = useParams();
    const { data: act } = api.useGetPerformerQuery(+id ?? skipToken);

    return (
        <Page title={act?.name ?? ''}>
            <div className="container-fluid">
                <div className="row">
                    <div className="col"></div>
                </div>
                <div className="row">
                    <div className="col-md-6">{act && <ContactListTable entity={act} />}</div>
                </div>
                <br />
            </div>
        </Page>
    );
};

export default ActDetailPage;
