import { currencyFormatter } from '..';

interface LocalProps {
    value?: number;
}

const CurrencyDisplay = ({ value = 0 }: LocalProps) => {
    return <>{currencyFormatter.format(value)}</>;
};

export default CurrencyDisplay;
