import { useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import { api } from '../../../store/api';
import { debounce } from 'lodash';

interface LocalProps {
    contractId: number;
    additionalTerms: string;
}

const ContractAdditionalTerms = ({ contractId, additionalTerms }: LocalProps) => {
    const [updateAdditionalTerms] = api.useUpdateAdditionalTermsMutation();
    const [value, setValue] = useState(additionalTerms);

    const debouncedUpdate = useMemo(
        () => debounce((v: string) => updateAdditionalTerms({ contractId, additionalTerms: v }), 500),
        [contractId, updateAdditionalTerms]
    );

    const onChange = (v: string) => {
        setValue(v);
        debouncedUpdate(v);
    };

    return (
        <Form.Group>
            <Form.Label className="text-muted">
                <small>Additional Terms</small>
            </Form.Label>
            <Form.Control className="" as="textarea" rows={2} value={value} onChange={(e) => onChange(e.target.value)} />
        </Form.Group>
    );
};

export default ContractAdditionalTerms;
