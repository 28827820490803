import { faFileContract } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { BookingPerformerModel } from '../../../models';
import PerformerContractOffCanvas from '../../documents/performerContract/PerformerContractOffCanvas';
import { AccountNameLazy } from '../../shared/components/ClientName';
import ContactCard from '../../shared/components/contacts/ContactCard';
import ContractPerformerEditButtonSuite from './components/ContractPerformerEditButtonSuite';
import ContractPerformerTermsInfo from './components/ContractPerformerTermsInfo';

interface LocalProps {
    bookingId: number;
    performer: BookingPerformerModel;
}

const ContractPerformerCard = ({ bookingId, performer }: LocalProps) => {
    const [showContract, setShowContract] = useState(false);
    return (
        <>
            <Card className="performer-color-code">
                <Card.Header>
                    <div className="d-flex justify-content-between">
                        <h5>
                            <small className="text-muted">Performer </small>
                            <AccountNameLazy accountId={performer.actId} />
                        </h5>
                        <ContractPerformerEditButtonSuite contractId={bookingId} performer={performer} />
                    </div>
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col xl>
                            <div className="authorized-contact-card">
                                <ContactCard title="Performer" contactId={performer.authorizedContactId} />
                            </div>
                        </Col>
                        <Col xl>
                            <div className="d-flex justify-content-end">
                                <Button className="mb-3" onClick={() => setShowContract(true)}>
                                    <FontAwesomeIcon icon={faFileContract} /> View Performer Contract
                                </Button>
                            </div>

                            <ContractPerformerTermsInfo performer={performer} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <small className="text-muted">Notes: </small>
                            {performer.notes}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <small className="text-muted">Additional Terms: </small>
                            {performer.additionalTerms}
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <PerformerContractOffCanvas show={showContract} bookingId={bookingId} bookingPerformer={performer} onHide={() => setShowContract(false)} />
        </>
    );
};

export default ContractPerformerCard;
