import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    label: {
        color: 'grey',
        fontSize: 10,
        //alignSelf: 'flex-end',
    },
    body: {
        fontSize: 10,
        color: '#292929',
    },
});

interface LocalProps {
    label: string;
    value?: string;
}

const LabeledValueView = ({ label, value }: LocalProps) => {
    return (
        <View style={{ flexDirection: 'row', marginTop: 3 }}>
            <Text style={styles.label}>{label} : </Text>
            <Text style={styles.body}>{value}</Text>
        </View>
    );
};

export default LabeledValueView;
