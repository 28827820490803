import { createSelector, createSlice, PayloadAction, SliceCaseReducers, ValidateSliceCaseReducers } from '@reduxjs/toolkit';
import { isEqual as _isEqual } from 'lodash';
import { RootState } from '../../store';

export interface EntityEditState<T> {
    isEditing: boolean;
    contractId?: number;
    original?: T;
    workingCopy?: T;
}

const createEntityEditSlice = <T, Reducers extends SliceCaseReducers<EntityEditState<T>>>({
    name = '',
    initialState,
    reducers,
}: {
    name: string;
    initialState: EntityEditState<T>;
    reducers: ValidateSliceCaseReducers<EntityEditState<T>, Reducers>;
}) => {
    return createSlice({
        name,
        initialState,
        reducers: {
            open: (state: EntityEditState<T>, action: PayloadAction<{ contractId: number; entity: T }>) => {
                state.contractId = action.payload.contractId;
                state.original = action.payload.entity;
                state.workingCopy = action.payload.entity;
                state.isEditing = true;
            },
            close: (state: EntityEditState<T>) => {
                state.isEditing = false;
                state.original = undefined;
                state.workingCopy = undefined;
            },
            updateWorkingCopy: (state: EntityEditState<T>, action: PayloadAction<T>) => {
                if (state.workingCopy) {
                    state.workingCopy = { ...action.payload };
                }
            },
            ...reducers,
        },
    });
};

export const createIsPendingChangesSelector = <T>(selectState: (state: RootState) => EntityEditState<T>) =>
    createSelector([selectState], (state) => {
        return !!(state.isEditing && state.original && state.workingCopy && !_isEqual(state.original, state.workingCopy));
    });

export default createEntityEditSlice;
