import { useEffect, useState } from 'react';
import ReactSelect, { SingleValue } from 'react-select';
import { AccountModel } from '../../../models';

export interface AccountSelectProps {
    selectedAccountId?: number;
    onChange?: (account: AccountModel) => void;
    placeholder?: string;
}

interface AccountOption {
    account: AccountModel;
    value: number;
    label: string;
}

const toOption = (account: AccountModel) => ({ account: account, value: account.id, label: account.name });

interface InternalProps extends AccountSelectProps {
    searchString: string;
    setSearchString: React.Dispatch<React.SetStateAction<string>>;
    accounts?: AccountModel[];
    selected?: AccountModel;
}

const AccountSelect = ({ selectedAccountId, onChange, placeholder, searchString, setSearchString, accounts, selected }: InternalProps) => {
    const [options, setOptions] = useState<AccountOption[]>();

    const _onChange = (option?: SingleValue<AccountOption>) => onChange && option && onChange(option.account);

    useEffect(() => {
        if (searchString.length >= 2) {
            if (accounts?.length) {
                setOptions(accounts.map(toOption));
            }
        } else {
            setOptions([]);
            setSearchString('');
        }
    }, [accounts, searchString, setSearchString]);

    useEffect(() => {
        if (selected) {
            if (!options?.some((x) => x.account.id === selectedAccountId)) {
                setOptions([...(options ?? []), toOption(selected)]);
            }
        }
    }, [selected, options, selectedAccountId]);

    const currentValue = options?.find((x) => x.account.id === selectedAccountId);

    return (
        <ReactSelect options={options} value={currentValue} onInputChange={(value) => setSearchString(value)} onChange={_onChange} placeholder={placeholder} />
    );
};

export default AccountSelect;
