import { Col, Row } from 'react-bootstrap';
import Page from '../app/Page';

const DashboardPage = () => {
    return (
        <Page title="Dashboard">
            <div className="container-fluid">
                <Row>
                    <Col></Col>
                </Row>
            </div>
        </Page>
    );
};

export default DashboardPage;
