import { PDFViewer, StyleSheet } from '@react-pdf/renderer';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Offcanvas } from 'react-bootstrap';
import { api } from '../../../store/api';
import ClientContractDocument from './ClientContractDocument';

const styles = StyleSheet.create({
    viewer: {
        width: window.innerWidth * 0.7,
        height: window.innerHeight - 85,
    },
});

interface LocalProps {
    show: boolean;
    contractId?: number;
    onHide: () => void;
}

const ClientContractOffCanvas = ({ contractId, show, onHide }: LocalProps) => {
    const { data: booking } = api.useGetBookingQuery(contractId ?? skipToken);
    const { data: client } = api.useGetClientQuery(booking?.clientId ?? skipToken);
    const { data: contact } = api.useGetContactQuery(booking?.authorizedContactId ?? skipToken);
    const { data: performers = [] } = api.useGetManyAccountsQuery(booking?.performers.map((x) => x.actId) ?? skipToken);

    return (
        <Offcanvas className="w-75" show={show} onHide={onHide} placement="end">
            <Offcanvas.Header closeButton></Offcanvas.Header>
            <Offcanvas.Body>
                <PDFViewer style={styles.viewer}>
                    <ClientContractDocument booking={booking} client={client} authorizedContact={contact} performers={performers} />
                </PDFViewer>
            </Offcanvas.Body>
        </Offcanvas>
    );
};

export default ClientContractOffCanvas;
