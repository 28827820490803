interface LocalProps {
    contractId: number;
}

const ContractName = ({ contractId }: LocalProps) => {
    const contractName = `Booking #${contractId}`; //  TODO: Probably use performer name here...

    return <h3>{contractName}</h3>;
};

export default ContractName;
