import { Middleware, MiddlewareAPI, configureStore, isRejectedWithValue } from '@reduxjs/toolkit';
import currentUserState from './currentUserSlice';
import addressSearchResults from './addressSearchSlice';
import contactMaintenance from './contactMaintenanceSlice';
import contractEditClient from './contracts/editSlices/contractEditClientSlice';
import contractStatusTypes from './types/contractStatusTypeSlice';
import accountTypes from './types/accountTypeSlice';
import contactMethodTypes from './types/contactMethodTypeSlice';
import contractEditPerformer from './contracts/editSlices/contractEditPerformerSlice';
import contractEditPerformance from './contracts/editSlices/contractEditPerformanceSlice';
import { api } from './api';
import toast from 'react-hot-toast';

export const rtkQueryErrorLogger: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action)) {
        console.warn('We got a rejected action!');
        toast.error(action.payload.data ?? 'Unexpected error, please refresh the page and try again.');
    }

    return next(action);
};

export const store = configureStore({
    reducer: {
        currentUserState,
        addressSearchResults,
        contactMaintenance,
        contractStatusTypes,
        accountTypes,
        contactMethodTypes,
        contractEditClient,
        contractEditPerformer,
        contractEditPerformance,
        [api.reducerPath]: api.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware, rtkQueryErrorLogger),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
