import { Spinner } from 'react-bootstrap';

interface LocalProps {
    spinner?: boolean;
}

const LoadingOverlay = ({ spinner }: LocalProps) => {
    return <div className="loading-overlay text-center">{spinner && <Spinner animation="border" />}</div>;
};

export default LoadingOverlay;
