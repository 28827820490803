import { Card, Col, Form, Row } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../../../hooks/rtk';
import { contractClientEditActions as actions } from '../../../../store/contracts';

const EditClientAudienceCard = () => {
    const dispatch = useAppDispatch();
    const workingCopy = useAppSelector((state) => state.contractEditClient.workingCopy);

    if (!workingCopy) return null;

    return (
        <Card>
            <Card.Header>Audience</Card.Header>
            <Card.Body>
                <Row>
                    <Col>
                        <Form.Label>Audience Age</Form.Label>
                        <Form.Control
                            type="text"
                            value={workingCopy.audienceAge ?? ''}
                            onChange={(e) => dispatch(actions.updateWorkingCopy({ ...workingCopy, audienceAge: e.target.value }))}
                        />
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col>
                        <Form.Label>Audience Size</Form.Label>
                        <Form.Control
                            type="text"
                            value={workingCopy.audienceSize ?? ''}
                            onChange={(e) => dispatch(actions.updateWorkingCopy({ ...workingCopy, audienceSize: e.target.value }))}
                        />
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

export default EditClientAudienceCard;
