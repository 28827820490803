import { Badge } from 'react-bootstrap';
import { api } from '../../../store/api';

interface LocalPropsLazy {
    accountId: number;
}

export const AccountNameLazy = ({ accountId }: LocalPropsLazy) => {
    const { data: act } = api.useGetClientQuery(accountId);

    return act ? <AccountName name={act.name} isBoces={act.isBoces} /> : <></>;
};

interface LocalProps {
    name: string;
    isBoces: boolean;
}

export const AccountName = ({ name, isBoces }: LocalProps) => {
    const bocesBadge = (
        <Badge className="ms-2" bg="danger">
            Boces
        </Badge>
    );

    return (
        <>
            {name}
            {isBoces && bocesBadge}
        </>
    );
};
