import { Text, View } from '@react-pdf/renderer';
import SignatureSection from '../../shared/SignatureSection';
import TermsOfAgreement from './TermsOfAgreement';

const StaticBottom = () => (
    <View style={{ padding: 10, marginRight: 10 }}>
        <TermsOfAgreement />
        <View style={{ flexDirection: 'row', fontSize: 9, marginTop: 5, marginBottom: 10, marginHorizontal: 10, padding: 5, backgroundColor: '#A0B4D9' }}>
            <View style={{ width: '50%' }}>
                <View style={{ alignItems: 'center', color: '#212121' }}>
                    <Text style={{ color: 'black' }}>Please sign and return the contract with your deposit to:</Text>
                    <Text>Class Act Performing Artists & Speakers, Inc.</Text>
                    <Text>W5784 Woodlawn Dr</Text>
                    <Text>Fort Atkinson WI 53538</Text>
                </View>
            </View>
            <View style={{ width: '50%' }}>
                <View style={{ alignItems: 'center', color: '#212121' }}>
                    <Text style={{ color: 'black' }}>Please Contact us with any questions.</Text>
                    <Text style={{ marginTop: 5 }}>Phone: (262) 245-0700</Text>
                    <Text>Email: class-act@class-act.com</Text>
                </View>
            </View>
        </View>
        <View style={{ flexDirection: 'row', fontSize: 11 }}>
            <SignatureSection />
        </View>
    </View>
);

export default StaticBottom;
