import { AppDateModel } from '../../../models';
import { DateTime } from 'luxon';

interface LocalProps {
    date?: AppDateModel;
    includeDayOfWeek?: boolean;
    letBrowserConvertToLocal?: boolean;
}

const AppDate = ({ date, includeDayOfWeek = false, letBrowserConvertToLocal = false }: LocalProps) => {
    if (!date) {
        return null;
    }
    const displayString = toDisplayString(date, includeDayOfWeek, letBrowserConvertToLocal);

    return <>{displayString}</>;
};

export const appDateSort = (a?: AppDateModel, b?: AppDateModel) => {
    const beginningOrRecordedtime = new Date('1970-01-01Z00:00:00:000');
    const aDate = a ? new Date(a.year, a.month - 1, a.day, a.hour, a.minute) : beginningOrRecordedtime;
    const bDate = b ? new Date(b.year, b.month - 1, b.day, b.hour, b.minute) : beginningOrRecordedtime;

    if (aDate > bDate) {
        return 1;
    }

    if (bDate > aDate) {
        return -1;
    }

    return 0;
};

export const toDisplayString = (date: AppDateModel, includeDayOfWeek: boolean, letBrowserConvertToLocal: boolean) => {
    if (date.isDateOnly) {
        const dt = DateTime.local(date.year, date.month, date.day);

        return dt.toFormat(`MM/dd/yyyy${includeDayOfWeek ? ' - EEEE' : ''}`);
    } else if (date.isUtc) {
        let dt = DateTime.utc(date.year, date.month, date.day, date.hour, date.minute);

        if (letBrowserConvertToLocal) {
            dt = dt.toLocal();
        }

        return dt.toFormat(`${includeDayOfWeek ? 'EEE ,' : ''}MM/dd/yyyy h:mm a ZZZZ`);
    } else {
        return '**** Do not know how to display this date ****';
    }
};

export default AppDate;
