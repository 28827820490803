import { Card } from 'react-bootstrap';
import { BookingChangeLogModel } from '../../../models';
import AppDate from '../../shared/components/AppDate';

interface LocalProps {
    logs: BookingChangeLogModel[];
}

const ContractChangeLogs = ({ logs }: LocalProps) => {
    return (
        <>
            <Card>
                <table className="table table-condensed table-hover">
                    <thead>
                        <tr>
                            <th>User</th>
                            <th>Time</th>
                            <th>Change Made</th>
                        </tr>
                    </thead>
                    <tbody>
                        {logs.map((log) => (
                            <tr key={log.id}>
                                <td>{log.username}</td>
                                <td>
                                    <AppDate date={log.date} letBrowserConvertToLocal />
                                </td>
                                <td>{log.log}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Card>
        </>
    );
};

export default ContractChangeLogs;
