import { faEdit, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../../../hooks/rtk';
import { AccountModel, AddressModel, AppDateModel } from '../../../../models';
import { PerformanceEditModel, PerformanceModel } from '../../../../models/bookings';
import { api } from '../../../../store/api';
import { contractEditPerformanceActions, contractEditPerformanceSelectors } from '../../../../store/contracts/editSlices/contractEditPerformanceSlice';
import ActSelect from '../../../shared/components/ActSelect';
import AddressSelect from '../../../shared/components/AddressSelect';
import ConfirmCancelModal from '../../../shared/components/ConfirmCancelModal';
import DeleteButton from '../../../shared/components/DeleteButton';
import EditAddButton from '../../../shared/components/IconButton';
import SaveCancelButtonRow from '../../../shared/components/SaveCancelButtonRow';
import EntityEditOffcanvas from '../../../shared/EntityEditOffcanvas';
import AppDatePicker from '../../../shared/components/AppDatePicker';

interface LocalProps {
    contractId: number;
    performance: PerformanceModel;
}

const PerformanceEditButtonSuite = ({ contractId, performance }: LocalProps) => {
    const dispatch = useAppDispatch();
    const state = useAppSelector((state) => state.contractEditPerformance);
    const isPendingChanges = useAppSelector(contractEditPerformanceSelectors.selectIsPendingChanges());
    const [confirmCancel, setConfirmCancel] = useState(false);
    const [editPerformance] = api.useEditPerformanceMutation();
    const [_removePerformance] = api.useRemovePerformanceMutation();

    const cancel = () => {
        dispatch(contractEditPerformanceActions.close());
        setConfirmCancel(false);
    };

    const onEditClicked = () => {
        dispatch(
            contractEditPerformanceActions.open({
                contractId,
                entity: performance,
            })
        );
    };

    const cancelClicked = () => {
        if (isPendingChanges) {
            setConfirmCancel(true);
        } else {
            cancel();
        }
    };

    const onPerformanceDateChange = (date: AppDateModel) => {
        if (state.workingCopy) {
            dispatch(contractEditPerformanceActions.updateWorkingCopy({ ...state.workingCopy, date }));
        }
    };

    const onActChange = (act: AccountModel) => {
        if (state.workingCopy) {
            dispatch(contractEditPerformanceActions.updateWorkingCopy({ ...state.workingCopy, actId: act.id }));
        }
    };

    const onNumberOfPerformersChanage = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (state.workingCopy) {
            dispatch(contractEditPerformanceActions.updateWorkingCopy({ ...state.workingCopy, numberOfPerformers: e.target.value }));
        }
    };

    const removePerformance = () => {
        if (performance) {
            _removePerformance({ contractId, performanceId: performance?.id });
            dispatch(contractEditPerformanceActions.close());
        }
    };

    const onNewAddressSelected = (address: AddressModel) => {
        if (state.workingCopy && state.workingCopy.address?.id === address.id) {
        } else {
            if (state.workingCopy) {
                dispatch(contractEditPerformanceActions.updateWorkingCopy({ ...state.workingCopy, address }));
            }
        }
    };

    const onVenueNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (state.workingCopy) {
            dispatch(contractEditPerformanceActions.updateWorkingCopy({ ...state.workingCopy, venueName: e.target.value }));
        }
    };

    const onNumberOfPerformancesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (state.workingCopy) {
            dispatch(contractEditPerformanceActions.updateWorkingCopy({ ...state.workingCopy, numberOfPerformances: +e.target.value }));
        }
    };

    const onPerformanceTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (state.workingCopy) {
            dispatch(contractEditPerformanceActions.updateWorkingCopy({ ...state.workingCopy, performanceTime: e.target.value }));
        }
    };

    const onCheckinTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (state.workingCopy) {
            dispatch(contractEditPerformanceActions.updateWorkingCopy({ ...state.workingCopy, checkInTime: e.target.value }));
        }
    };

    const onShowLengthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (state.workingCopy) {
            dispatch(contractEditPerformanceActions.updateWorkingCopy({ ...state.workingCopy, showLength: e.target.value }));
        }
    };

    const save = () => {
        if (state.workingCopy) {
            const { address, ...matchinProps } = state.workingCopy;
            const model: PerformanceEditModel = {
                addressId: address?.id,
                ...matchinProps,
            };
            editPerformance({ contractId, model });
            dispatch(contractEditPerformanceActions.close());
        }
    };

    const isEditing = state.isEditing && state.workingCopy?.id === performance.id;

    return (
        <>
            <EditAddButton icon={performance.id ? faEdit : faPlusCircle} onClick={onEditClicked} />
            <EntityEditOffcanvas
                title={performance.id ? 'Edit Performance' : 'Create New Performance'}
                show={isEditing}
                onHide={cancelClicked}
                isPendingChanges={isPendingChanges}
            >
                {state.workingCopy && (
                    <>
                        <Row>
                            <Col>
                                <Form.Label>Performer</Form.Label>
                                <ActSelect placeholder="Performer" selectedAccountId={state.workingCopy.actId} onChange={onActChange} />
                            </Col>
                            <Col>
                                <Form.Label>Number of Performers</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={state.workingCopy.numberOfPerformers ?? ''}
                                    onChange={onNumberOfPerformersChanage}
                                ></Form.Control>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col>
                                <Form.Group>
                                    <Form.Label>Performance Date</Form.Label>
                                    <AppDatePicker value={state.workingCopy.date} onChange={onPerformanceDateChange} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Performance Start Time(s)</Form.Label>
                                    <Form.Control type="text" value={state.workingCopy?.performanceTime ?? ''} onChange={onPerformanceTimeChange} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col>
                                <Form.Group>
                                    <Form.Label>Check-in Time</Form.Label>
                                    <Form.Control type="text" value={state.workingCopy?.checkInTime ?? ''} onChange={onCheckinTimeChange} />
                                </Form.Group>
                            </Col>

                            <Col>
                                <Form.Group>
                                    <Form.Label>Number of Performances</Form.Label>
                                    <Form.Control type="number" value={state.workingCopy?.numberOfPerformances ?? ''} onChange={onNumberOfPerformancesChange} />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className="mt-3">
                            <Col></Col>

                            <Col>
                                <Form.Group>
                                    <Form.Label>Show Length</Form.Label>
                                    <Form.Control type="text" value={state.workingCopy?.showLength ?? ''} onChange={onShowLengthChange} />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className="mt-3">
                            <Col>
                                <Form.Group>
                                    <Form.Label>Location Name</Form.Label>
                                    <Form.Control type="text" value={state.workingCopy?.venueName ?? ''} onChange={onVenueNameChange} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col>
                                <AddressSelect selected={state.workingCopy?.address} onChange={onNewAddressSelected} />
                            </Col>
                        </Row>
                    </>
                )}

                <SaveCancelButtonRow
                    className="mt-2 justify-content-end"
                    cancel={cancelClicked}
                    save={save}
                    isPendingChanges={isPendingChanges || performance.id === 0}
                    deleteButton={
                        performance.id > 0 && (
                            <DeleteButton
                                buttonText="Delete Performance"
                                message="Are you sure you would like to remove this performance?"
                                onConfirm={removePerformance}
                            />
                        )
                    }
                />
            </EntityEditOffcanvas>
            <ConfirmCancelModal show={confirmCancel} cancelAborted={() => setConfirmCancel(false)} cancelConfirmed={cancel} cancelClicked={cancelClicked} />
        </>
    );
};

export default PerformanceEditButtonSuite;
