import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import { AccountModel } from '../../../models';

interface LocalProps {
    accounts: AccountModel[];
    path: string;
}

const AccountsTable = ({ accounts, path }: LocalProps) => {
    const navigate = useNavigate();
    const [filtered, setFiltered] = useState(accounts);

    useEffect(() => setFiltered(accounts), [accounts]);

    const onFilterChange = (value: string) => {
        if (value && value.length > 2) {
            setFiltered(accounts.filter((x) => x.name.toLocaleLowerCase().includes(value.toLocaleLowerCase())));
        } else {
            setFiltered(accounts);
        }
    };

    const columns: TableColumn<AccountModel>[] = [
        {
            selector: (row) => row.id,
            cell: (row) => (
                <Button variant="secondary" size="sm" onClick={() => navigate(`../${path}/${row.id}`, { replace: true })}>
                    <FontAwesomeIcon icon={faEdit} />
                </Button>
            ),
            center: true,
            maxWidth: '5em',
        },
        {
            name: 'Name',
            selector: (row) => row.name,
        },
    ];

    const filterHeader = (
        <div className="d-flex">
            <span className="m-2">Filter&nbsp;:</span>
            <Form.Control type="text" onChange={(x) => onFilterChange(x.target.value)} />
        </div>
    );

    return <DataTable pagination highlightOnHover striped columns={columns} data={filtered} subHeader subHeaderComponent={filterHeader} />;
};

export default AccountsTable;
