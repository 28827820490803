import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faCalendar, faCog, faCommentsDollar, faDashboard, faMask } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';
import { SignOutMenuItem } from '../shared';
import NewBookingButton from '../dashboard/components/NewBookingButton';

//https://juchazari.medium.com/persisting-a-sidebars-expanded-collapsed-state-with-react-and-local-storage-69e9d6feb52b

const SideBar = () => {
    const sidebarCollapsed = localStorage.getItem('sidebar-collapsed');
    const [isExpanded, setIsExpanded] = useState(sidebarCollapsed ? false : true);

    const handleToggle = () => {
        if (isExpanded) {
            setIsExpanded(false);
            localStorage.setItem('sidebar-collapsed', 'true');
        } else {
            setIsExpanded(true);
            localStorage.removeItem('sidebar-collapsed');
        }
    };

    return (
        <div className={isExpanded ? 'sidebar' : 'sidebar collapsed'}>
            <div className="sidebar-header" onClick={handleToggle}>
                <FontAwesomeIcon icon={faBars} />
            </div>
            <div className="sidebar-items">
                <NavLink to="/" className="item">
                    <FontAwesomeIcon icon={faDashboard} className="sidebar-icon" />
                    <span className="sidebar-text">Dashboard</span>
                </NavLink>
                <NavLink to="/calendar" className="item">
                    <FontAwesomeIcon icon={faCalendar} className="sidebar-icon" />
                    <span className="sidebar-text">Calendar</span>
                </NavLink>
                <NavLink to="/clients" className="item mt-3">
                    <FontAwesomeIcon icon={faCommentsDollar} className="sidebar-icon" />
                    <span className="sidebar-text">Clients</span>
                </NavLink>
                <NavLink to="/acts" className="item">
                    <FontAwesomeIcon icon={faMask} className="sidebar-icon" />
                    <span className="sidebar-text">Acts</span>
                </NavLink>
                <NewBookingButton />
                <NavLink to="/maintenance" className="item mt-3">
                    <FontAwesomeIcon icon={faCog} className="sidebar-icon" />
                    <span className="sidebar-text">Maintenance</span>
                </NavLink>
                <SignOutMenuItem />
            </div>
        </div>
    );
};

export default SideBar;
