import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import { contactMethodTypesClient } from '../../api';
import { ContactMethodType } from '../../models';
import { RootState } from '../store';

const adapter = createEntityAdapter<ContactMethodType>({
    selectId: (x) => x.id,
    sortComparer: (a, b) => a.name.localeCompare(b.name),
});

const getAll = createAsyncThunk('contactMethodTypes/getall', async (thunkAPI) => {
    const response = await contactMethodTypesClient.getAll();
    return response;
});

const slice = createSlice({
    name: 'contactMethodTypes',
    initialState: adapter.getInitialState({
        isLoading: false,
    }),
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAll.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(getAll.fulfilled, (state, action) => {
            adapter.setAll(state, action.payload);
        });
    },
});

const selectors = adapter.getSelectors<RootState>((state) => state.contactMethodTypes);

const selectEmailType = createSelector([selectors.selectAll], (allTypes) => allTypes.find((x) => x.isEmail));
const selectPhoneType = createSelector([selectors.selectAll], (allTypes) => allTypes.find((x) => x.isPhone));
const contactMethodTypesSelectors = { ...selectors, selectEmailType, selectPhoneType };
const contactMethodTypesActions = { ...slice.actions, getAll };

export default slice.reducer;
export { contactMethodTypesSelectors, contactMethodTypesActions };
