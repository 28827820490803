import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AccountType, ContactModel } from '../models';

interface ContactMaintenanceState {
    entityId: number;
    accountType?: AccountType;
    isModalOpen: boolean;
    workingContact: ContactModel;
}

const initialState: ContactMaintenanceState = {
    workingContact: {
        id: 0,
        firstName: '',
        lastName: '',
        title: '',
        contactMethods: [],
    },
    entityId: 0,
    isModalOpen: false,
};

interface ContactMethodEditPayload {
    index: number;
    value: string;
}

interface ContactEditPayload {
    entityId: number;
    contact: ContactModel;
    accountType: AccountType;
}

export const slice = createSlice({
    name: 'addressSearch',
    initialState,
    reducers: {
        createContact: (state, action: PayloadAction<{ accountId: number; accountType: AccountType }>) => {
            state.workingContact = {
                id: 0,
                firstName: '',
                lastName: '',
                title: '',
                contactMethods: [],
            };
            state.entityId = action.payload.accountId;
            state.accountType = action.payload.accountType;
            state.isModalOpen = true;
        },
        editContact: (state, action: PayloadAction<ContactEditPayload>) => {
            state.entityId = action.payload.entityId;
            state.workingContact = action.payload.contact;

            state.accountType = action.payload.accountType;
            state.isModalOpen = true;
        },
        close: (state) => {
            state.workingContact = {
                id: 0,
                firstName: '',
                lastName: '',
                title: '',
                contactMethods: [],
            };
            state.isModalOpen = false;
        },
        setFirstName: (state, action: PayloadAction<string>) => {
            state.workingContact.firstName = action.payload;
        },
        setLastName: (state, action: PayloadAction<string>) => {
            state.workingContact.lastName = action.payload;
        },
        setTitle: (state, action: PayloadAction<string>) => {
            state.workingContact.title = action.payload;
        },
        toggleContactMethodType: (state, action: PayloadAction<number>) => {
            state.workingContact.contactMethods[action.payload].contactMethodTypeId =
                state.workingContact.contactMethods[action.payload].contactMethodTypeId === 1 ? 2 : 1;
        },
        setContactMethodName: (state, action: PayloadAction<ContactMethodEditPayload>) => {
            state.workingContact.contactMethods[action.payload.index].name = action.payload.value;
        },
        setContactMethodValue: (state, action: PayloadAction<ContactMethodEditPayload>) => {
            state.workingContact.contactMethods[action.payload.index].value = action.payload.value;
        },
        addContactMethod: (state) => {
            state.workingContact.contactMethods.push({ id: 0, name: '', value: '', contactMethodTypeId: 2 });
        },
    },
    extraReducers: (builder) => {},
});

export default slice.reducer;
const contactMaintenanceActions = {
    ...slice.actions,
};

export { contactMaintenanceActions };
