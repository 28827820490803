import { faEdit, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../../../hooks/rtk';
import useClientAccountType from '../../../../hooks/useClientAccountType';
import { Booking, toAppDate } from '../../../../models';
import { api } from '../../../../store/api';
import { contactMaintenanceActions } from '../../../../store/contactMaintenanceSlice';
import { contractClientEditActions, contractClientEditSelectors } from '../../../../store/contracts';
import ConfirmCancelModal from '../../../shared/components/ConfirmCancelModal';
import ContactSearchDropDown from '../../../shared/components/ContactSearchDropDown';
import IconButton from '../../../shared/components/IconButton';
import SaveCancelButtonRow from '../../../shared/components/SaveCancelButtonRow';
import EntityEditOffcanvas from '../../../shared/EntityEditOffcanvas';
import EditClientAudienceCard from './EditClientAudienceCard';
import EditClientBillingCard from './EditClientBillingCard';

interface LocalProps {
    booking: Booking;
}

const ContractClientEditButtonSuite = ({ booking }: LocalProps) => {
    const dispatch = useAppDispatch();
    const [editClientInfo] = api.useEditClientInfoMutation();
    const { data: client } = api.useGetClientQuery(booking.clientId);
    const state = useAppSelector((state) => state.contractEditClient);
    const accountType = useClientAccountType();

    const isPendingChanges = useAppSelector(contractClientEditSelectors.selectIsPendingChanges());
    const [confirmCancel, setConfirmCancel] = useState(false);

    const onEditClicked = () => {
        dispatch(
            contractClientEditActions.open({
                contractId: booking.id,
                entity: booking,
            })
        );
    };

    const cancelClicked = () => {
        if (isPendingChanges) {
            setConfirmCancel(true);
        } else {
            cancel();
        }
    };

    const cancel = () => {
        dispatch(contractClientEditActions.close());
        setConfirmCancel(false);
    };

    const save = () => {
        if (state.workingCopy) {
            const model = {
                contractId: booking.id,
                model: state.workingCopy,
            };
            editClientInfo(model);
        }
        cancel();
    };

    const onNewClicked = () => {
        if (accountType) {
            dispatch(contactMaintenanceActions.createContact({ accountId: booking.clientId, accountType }));
        }
    };

    return (
        <>
            <IconButton icon={faEdit} onClick={onEditClicked} />
            <EntityEditOffcanvas title="Edit Client Detail" show={state.isEditing} onHide={cancelClicked} isPendingChanges={isPendingChanges}>
                <Form.Label>Authorized Contact</Form.Label>
                <div className="d-flex">
                    <div className="flex-grow-1 mx-2">
                        <ContactSearchDropDown
                            contactId={booking.authorizedContactId}
                            contacts={client?.contacts}
                            onChange={(contact) => {
                                if (state.workingCopy) {
                                    dispatch(contractClientEditActions.updateWorkingCopy({ ...state.workingCopy, authorizedContactId: contact.id }));
                                }
                            }}
                        />
                    </div>
                    <Button variant="primary" onClick={onNewClicked}>
                        <FontAwesomeIcon icon={faPlusCircle} /> New
                    </Button>
                </div>

                <Form.Label className="mt-2">Site Contact</Form.Label>
                <div className="d-flex">
                    <div className="flex-grow-1 mx-2">
                        <ContactSearchDropDown
                            contactId={booking.siteContactId}
                            contacts={client?.contacts}
                            onChange={(contact) => {
                                if (state.workingCopy) {
                                    dispatch(contractClientEditActions.updateWorkingCopy({ ...state.workingCopy, siteContactId: contact.id }));
                                }
                            }}
                        />
                    </div>
                    <Button variant="primary" onClick={onNewClicked}>
                        <FontAwesomeIcon icon={faPlusCircle} /> New
                    </Button>
                </div>

                <Row className="mt-3">
                    <Col className="mb-2">
                        <EditClientBillingCard />
                    </Col>
                    <Col>
                        <EditClientAudienceCard />
                        <div className="d-flex my-2">
                            <Form.Label>Contract Signed?</Form.Label>
                            <Form.Check
                                className="mx-3"
                                checked={state.workingCopy?.contractSignedDate ? true : false}
                                onChange={(x) => {
                                    if (state.workingCopy) {
                                        dispatch(
                                            contractClientEditActions.updateWorkingCopy({
                                                ...state.workingCopy,
                                                contractSignedDate: state.workingCopy.contractSignedDate ? undefined : toAppDate(new Date()),
                                            })
                                        );
                                    }
                                }}
                            />
                        </div>
                    </Col>
                </Row>
                <SaveCancelButtonRow className="mt-2 justify-content-end" cancel={cancelClicked} save={save} isPendingChanges={isPendingChanges} />
            </EntityEditOffcanvas>
            <ConfirmCancelModal show={confirmCancel} cancelAborted={() => setConfirmCancel(false)} cancelConfirmed={cancel} cancelClicked={cancelClicked} />
        </>
    );
};

export default ContractClientEditButtonSuite;
