import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    section: {
        fontSize: 12,
        color: '#454545',
    },
    sectionTitle: {
        fontSize: 14,
        marginTop: 10,
        textDecoration: 'underline',
    },
});

const IndemnificationAgreement = () => (
    <View style={{ padding: 10, marginTop: 20, marginHorizontal: 40 }}>
        <View style={styles.section}>
            <Text style={styles.sectionTitle}>State Withholding Tax:</Text>
            <Text>
                Artist agrees to be knowledgeable of each state, city, regional and local entertainment tax withholding and will be responsible for any
                withholding tax assessed by state of non-residence.
            </Text>
        </View>
        <View style={styles.section}>
            <Text style={styles.sectionTitle}>Insurance:</Text>
            <Text>Artist agrees to carry individual business and personal insurance. </Text>
        </View>
        <View style={styles.section}>
            <Text style={styles.sectionTitle}>Indemnification: Hold Harmless: </Text>
            <Text>
                Artist shall indemnify, safe and hold harmless Class Act, its Board of Trustees, employees, volunteers, agents, successors and assign against
                any liability, damages, claims or expenses ( including attorney fees) that Class Act may incur as a result of Artist’s performances or services
                hereunder or as a result of Artists’ breach of any representations, warranties or obligations hereunder, including but not limited to: Any
                violation or infringement of copyright, intellectual property right, privacy right or other statutory of common law right of any person, firm or
                corporation: The defamation of any firm, person or corporation; Any personal injury or property damage claims related to the services, unless
                occasioned by the gross negligence of Class Act, its employees or its agents. Contractor understands that they are an independent Contractor and
                not covered by Class Act Worker’s Compensation Insurance and agree that they shall not under any circumstances file a Worker’s Compensation
                claim against Class Act. The Independent Contractor further agrees to waive all rights of subrogation against Class Act.
            </Text>
        </View>
        <View style={styles.section}>
            <Text style={styles.sectionTitle}>Rebooking:</Text>
            <Text>
                It is agreed that all reoccurring contracts and contacts arising from a Class Act contact or booking will be referred to Class Act for
                execution. Rebooking includes bookings and interest arising from appearances as a result of a Class Act contact. This rebooking clause will
                remain in effect for five years following the execution of the date scheduled with Class Act client.
            </Text>
        </View>
        <View style={styles.section}>
            <Text style={styles.sectionTitle}>Governing Law:</Text>
            <Text>
                It is understood that Artist will be aware of and comply with all internal laws of country, state or province where performance is held.
            </Text>
        </View>
    </View>
);

export default IndemnificationAgreement;
