export const msalConfig = {
    auth: {
        clientId: '96bd3c9d-7c83-411b-9c54-26e9f3556dce',
        authority: 'https://login.microsoftonline.com/8d9dffff-1030-454a-88d5-dcc71bef018d', // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
        redirectUri: process.env.PUBLIC_URL,
    },
    cache: {
        cacheLocation: 'sessionStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: ['User.Read'],
};

export const classActRequest = {
    scopes: ['api://697ea78b-d89e-41c8-b806-615e9dcd955a/Contracts.Read'],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
    graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};
