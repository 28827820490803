import { Container, Row, Col, Accordion } from 'react-bootstrap';
import ContractStatus from './components/BookingStatusSelect';
import LoadingOverlay from '../shared/components/LoadingOverlay';
import useIntIdParams from '../../hooks/useIntIdParams';
import ContractChangeLogs from './components/ContractChangeLogs';
import ContractName from './components/ContractName';
import ContractNotes from './components/TrackingNotesSection';
import ContractClientCard from './client/ContractClientCard';
import ContractPerformerCard from './performers/ContractPerformerCard';
import ContractPerformancesCard from './performances/ContractPerformancesCard';
import { api } from '../../store/api';

const ContractDetailPage = () => {
    const id = useIntIdParams();
    const { data: booking } = api.useGetBookingQuery(id);

    return (
        <Container fluid>
            {booking ? (
                <>
                    <Row>
                        <Col md="8" className="mt-3">
                            <ContractName contractId={id} />
                        </Col>
                        <Col md="4" className="mt-3">
                            <ContractStatus bookingId={booking.id} statusTypeId={booking.statusTypeId} />
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6" className="mt-3">
                            <ContractClientCard booking={booking} />
                        </Col>
                        <Col md="6" className="mt-3">
                            {booking?.performers.map((performer) => (
                                <ContractPerformerCard bookingId={booking.id} performer={performer} key={performer.id} />
                            ))}
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6" className="mt-3">
                            <ContractPerformancesCard contractId={id} performances={booking.performances} />
                        </Col>
                        <Col md="6" className="mt-3">
                            <ContractNotes contractId={booking.id} notes={booking.trackingNotes} />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="mt-3">
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Change Logs</Accordion.Header>
                                    <Accordion.Body>
                                        <ContractChangeLogs logs={booking.logs} />
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                    </Row>
                </>
            ) : (
                <LoadingOverlay spinner />
            )}
        </Container>
    );
};

export default ContractDetailPage;
