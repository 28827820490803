import { Text, View } from '@react-pdf/renderer';
import { AccountModel, ContactModel } from '../../../../models';
import contractStyles from '../contractStyleSheet';
import LabeledValueView from './LabeledValueView';

interface LocalProps {
    client?: AccountModel;
    authorizedContact?: ContactModel;
}

const ClientSectionView = ({ client, authorizedContact }: LocalProps) => {
    return (
        <View>
            <View>
                <Text style={{ ...contractStyles.h2, marginTop: 0 }}>Client</Text>
            </View>
            <View>
                <Text style={{ ...contractStyles.h3, marginTop: 0 }}>{client?.name}</Text>
            </View>
            <LabeledValueView label="Authorized Contact" value={`${authorizedContact?.firstName} ${authorizedContact?.lastName}`} />
            <LabeledValueView label="Title" value={authorizedContact?.title} />
            <View style={{ marginLeft: 10 }}>
                {authorizedContact?.contactMethods.map((x) => (
                    <View key={x.id} style={{ flexDirection: 'row' }}>
                        <LabeledValueView label={x.name} value={x.value} />
                    </View>
                ))}
            </View>
        </View>
    );
};

export default ClientSectionView;
