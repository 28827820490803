import { useEffect, useState } from 'react';
import { AccountType } from '../models';
import { api } from '../store/api';

const useClientAccountType = () => {
    const { data: accountTypes } = api.useGetAllAccountTypesQuery();
    const [accountType, setAccountType] = useState<AccountType>();

    useEffect(() => {
        if (accountTypes?.length) {
            setAccountType(accountTypes.find((x) => x.isClient));
        }
    }, [accountTypes]);

    return accountType;
};

export default useClientAccountType;
