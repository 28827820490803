import Page from '../app/Page';
import { Button, Col, Row } from 'react-bootstrap';
import useAddAccountPopup from '../../hooks/useAddAccountPopup';
import useAllClients from '../../hooks/useAllClients';
import useClientAccountType from '../../hooks/useClientAccountType';
import AccountsTable from '../shared/components/AccountsTable';

const ActsPage = () => {
    const clients = useAllClients();
    const accountType = useClientAccountType();
    const [open, newClientPopup] = useAddAccountPopup();

    return (
        <Page title="Clients">
            <Row>
                <Col className="mb-2">
                    <AccountsTable accounts={clients} path="clients" />
                    {newClientPopup}
                </Col>
            </Row>
            <Row>
                <Col>
                    {accountType && (
                        <Button onClick={() => open(accountType)} variant="primary">
                            Add New Client
                        </Button>
                    )}
                </Col>
            </Row>
        </Page>
    );
};

export default ActsPage;
