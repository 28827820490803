import { View } from '@react-pdf/renderer';
import { AccountModel, Booking } from '../../../../models';
import contractStyles from '../contractStyleSheet';
import PaymentTermsSectionView from './PaymentTermsSectionView';
import PerformersSectionView from './PerformersSectionView';

interface LocalProps {
    contract?: Booking;
    performers?: AccountModel[];
}

const PerformerColumnView = ({ contract, performers }: LocalProps) => {
    return (
        <View style={contractStyles.performerColumn}>
            <PerformersSectionView contract={contract} performers={performers} />
            <PaymentTermsSectionView contract={contract} />
        </View>
    );
};

export default PerformerColumnView;
