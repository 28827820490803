import { Col, Form, Row } from 'react-bootstrap';
import { TrackingNoteModel } from '../../../../models/bookings';
import AppDate from '../../../shared/components/AppDate';

interface LocalProps {
    note: TrackingNoteModel;
}

const TrackingNote = ({ note }: LocalProps) => {
    return (
        <Row>
            <Col>
                <div className="m-1 mt-3">
                    <AppDate date={note.date} letBrowserConvertToLocal />: {note.username}
                </div>
                <Form.Control className="" as="textarea" rows={2} defaultValue={note.text} disabled></Form.Control>
            </Col>
        </Row>
    );
};

export default TrackingNote;
