import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOut } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';
import { useAppDispatch } from '../../../hooks/rtk';
import { reset } from '../../../store/currentUserSlice';
import { handleLogout } from '../../..';

export const SignOutMenuItem = () => {
    const dispatch = useAppDispatch();

    const _handleLogout = () => {
        dispatch(reset());
        handleLogout();
    };

    return (
        <NavLink to="/" className="item" onClick={_handleLogout}>
            <FontAwesomeIcon icon={faSignOut} className="sidebar-icon" />
            <span className="sidebar-text">Sign out</span>
        </NavLink>
    );
};
