import { useEffect, useState } from 'react';
import { AccountModel } from '../models';
import { api } from '../store/api';
import useActAccountType from './useActAccountType';

const useAllActs = () => {
    const accountType = useActAccountType();
    const { data: accounts } = api.useGetAllPerformersQuery();
    const [acts, setActs] = useState<AccountModel[]>([]);

    useEffect(() => {
        if (accountType && accounts?.length) {
            setActs(accounts.filter((x) => x.accountTypeId === accountType.id));
        }
    }, [accountType, accounts]);

    return acts;
};

export default useAllActs;
