import { api } from '../../../../store/api';

interface LocalProps {
    title: string;
    contactId?: number;
}

const ContactCard = ({ title, contactId }: LocalProps) => {
    const { data: contact } = api.useGetContactQuery(contactId ?? 0, { skip: !contactId });

    return (
        <div className="contact-card">
            <div className="contact-card-title">{title}</div>
            <div className="contact-card-body">
                {contact ? (
                    <>
                        <div>
                            {contact.firstName} {contact.lastName}
                        </div>
                        <div className="text-muted mx-1">
                            <small>{contact.title}</small>
                        </div>
                        <table>
                            <tbody>
                                {contact?.contactMethods.map((x) => (
                                    <tr key={x.id}>
                                        <td className="text-muted">
                                            <small className="mx-1">{x.name}</small>
                                        </td>
                                        <td>{x.value}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </>
                ) : (
                    <div className="d-flex justify-content-center">
                        <p className="lead">Not Set</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ContactCard;
