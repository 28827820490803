import { StyleSheet } from '@react-pdf/renderer';

const contractStyles = StyleSheet.create({
    page: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    h1: {
        color: '#274E96',
        fontSize: 15,
        marginTop: 10,
    },
    h2: {
        color: '#274E96',
        fontSize: 12,
        marginBottom: 5,
        marginTop: 10,
    },
    h3: {
        fontSize: 11,
        marginTop: 10,
    },
    label: {
        color: 'grey',
        fontSize: 8,
        alignSelf: 'flex-end',
    },
    body: {
        fontSize: 10,
        color: '#292929',
    },
    clientColumn: {
        width: '40%',
        marginLeft: 10,
        marginTop: 10,
    },
    performerColumn: {
        width: '60%',
        marginTop: 10,
    },
});

export default contractStyles;
