import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { api } from '../../../store/api';
import AddAccountAddressModel from './AddAccountAddressModel';
import EditableAddressName from './editable/EditableAddressName';

interface LocalProps {
    accountId: number;
}

const AccountAddressListCard = ({ accountId }: LocalProps) => {
    const [showAddModal, setShowAddModal] = useState(false);
    const { data: addresses } = api.useGetAccountAddressesQuery(accountId);

    return (
        <>
            <Card>
                <Card.Header className="d-flex justify-content-between">
                    <Card.Title>Addresses</Card.Title>
                    <Button size="sm" variant="secondary" onClick={() => setShowAddModal(true)}>
                        <FontAwesomeIcon icon={faPlusCircle} />
                    </Button>
                </Card.Header>
                <Card.Body>
                    {addresses?.map((x) => (
                        <Row className="mb-5" key={x.id}>
                            <Col>
                                <EditableAddressName name={x.name} accountId={accountId} addressId={x.id} />
                                <br />
                                {x.freeFormAddress}
                            </Col>
                        </Row>
                    ))}
                    <Row>
                        <Col></Col>
                    </Row>
                </Card.Body>
            </Card>
            <AddAccountAddressModel accountId={accountId} show={showAddModal} onHide={() => setShowAddModal(false)} />
        </>
    );
};

export default AccountAddressListCard;
