import { useMsal } from '@azure/msal-react';
import { useCallback, useEffect } from 'react';
import { handleLogout } from '../../..';
import { loginRequest } from '../../../authConfig';
import { callMsGraph } from '../../../graph';
import { useAppDispatch } from '../../../hooks/rtk';
import { setUser } from '../../../store/currentUserSlice';

export const CurrentUserLoader = () => {
    const { instance, accounts } = useMsal();
    const dispatch = useAppDispatch();

    // https://docs.microsoft.com/en-us/azure/active-directory/develop/tutorial-v2-react
    function RequestAccessToken() {
        const request = {
            ...loginRequest,
            account: accounts[0],
        };

        instance
            .acquireTokenSilent(request)
            .then((response) => {
                callMsGraph(response.accessToken).then((response) => {
                    dispatch(setUser(response));
                });
            })
            .catch((e) => handleLogout());
    }

    const memoizedCallback = useCallback(RequestAccessToken, [accounts, dispatch, instance]);

    useEffect(() => memoizedCallback(), [memoizedCallback]);
    return <></>;
};
