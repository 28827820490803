import { Form } from 'react-bootstrap';

interface LocalProps {
    value?: number;
    onChange?: (value: number) => void;
}

const NumericFormControl = ({ value, onChange }: LocalProps) => {
    const numericValue: number | undefined = value ? value : 0;
    const _value = numericValue === 0 ? '' : numericValue;
    const _onChange = (v: number) => {
        if (onChange) {
            onChange(v);
        }
    };

    return <Form.Control type="number" value={_value} onChange={(e) => _onChange(+e.target.value)} />;
};

export default NumericFormControl;
