import clsx from 'clsx';
import { Button, Col, Row } from 'react-bootstrap';

interface LocalProps {
    className?: string;
    save: () => void;
    cancel: () => void;
    isPendingChanges: boolean;
    deleteButton?: React.ReactNode;
}

const SaveCancelButtonRow = ({ className, save, cancel, isPendingChanges, deleteButton }: LocalProps) => {
    return (
        <Row>
            {deleteButton && <Col className={clsx('d-flex', className)}>{deleteButton}</Col>}
            <Col className={clsx('d-flex', className)}>
                <Button variant="secondary" onClick={cancel}>
                    Cancel
                </Button>
                <Button className="mx-2" disabled={!isPendingChanges} variant={isPendingChanges ? 'success' : 'outline-success'} onClick={save}>
                    Save
                </Button>
            </Col>
        </Row>
    );
};

export default SaveCancelButtonRow;
