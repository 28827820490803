import { Col, Row } from 'react-bootstrap';
import Page from '../app/Page';
import ContractStatusMaintCard from './components/ContractStatusMaintCard';

const MaintenanceHomePage = () => {
    return (
        <Page title="Settings">
            <Row>
                <Col>
                    <ContractStatusMaintCard />
                </Col>
                <Col></Col>
            </Row>
        </Page>
    );
};

export default MaintenanceHomePage;
