import { Form } from 'react-bootstrap';
import { AccountModel, AppDateModel } from '../../../models';
import ActSelect from './ActSelect';
import ClientSelect from './ClientSelect';
import AppDatePicker from './AppDatePicker';

interface LocalProps {
    selectedAct?: AccountModel;
    selectedClient?: AccountModel;
    onSelectedActChanged: (client: AccountModel) => void;
    onSelectedClientChanged: (client: AccountModel) => void;
    selectedDate: AppDateModel;
    onDateChange: (date: AppDateModel) => void;
}

const NewBookingForm = ({ selectedAct, selectedClient, onSelectedActChanged, onSelectedClientChanged, selectedDate, onDateChange }: LocalProps) => {
    return (
        <>
            <Form.Group className="m-2">
                <Form.Label className="fw-bold">Client</Form.Label>
                <ClientSelect selectedAccountId={selectedClient?.id} onChange={onSelectedClientChanged} placeholder="select client" />
            </Form.Group>
            <Form.Group className="m-2">
                <Form.Label className="fw-bold">Act</Form.Label>
                <ActSelect selectedAccountId={selectedAct?.id} onChange={onSelectedActChanged} placeholder="select act" />
            </Form.Group>
            <div>
                <AppDatePicker onChange={onDateChange} value={selectedDate} />
            </div>
        </>
    );
};

export default NewBookingForm;
