import { Document, Page, Text, View } from '@react-pdf/renderer';
import { AccountModel, ContactModel, Booking } from '../../../models';
import StaticBottom from './components/StaticBottom';
import PageHeaderView from './components/PageHeaderView';
import contractStyles from './contractStyleSheet';
import ClientColumnView from './components/ClientColumnView';
import PerformerColumnView from './components/PerformerColumnView';

interface LocalProps {
    booking?: Booking;
    client?: AccountModel;
    authorizedContact?: ContactModel;
    performers?: AccountModel[];
}

const ClientContractDocument = ({ booking, client, authorizedContact, performers }: LocalProps) => {
    return (
        <Document>
            <Page size="A4" style={contractStyles.page}>
                <View>
                    <View>
                        <PageHeaderView booking={booking} />
                        <View style={{ flexDirection: 'column', alignItems: 'center' }}>
                            <Text style={{ ...contractStyles.h1, marginTop: 0 }}>Class Act Performance Contract</Text>
                        </View>
                        <View style={{ flexDirection: 'row' }}>
                            <ClientColumnView {...{ booking, client, authorizedContact }} />
                            <PerformerColumnView contract={booking} performers={performers} />
                        </View>
                    </View>
                    <View style={{ ...contractStyles.body, marginLeft: 10 }}>
                        <Text style={contractStyles.h2}>Additional Terms</Text>
                        <Text>{booking?.additionalTerms}</Text>
                    </View>
                </View>
                <View>
                    <StaticBottom />
                </View>
            </Page>
        </Document>
    );
};

export default ClientContractDocument;
