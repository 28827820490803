import { Text } from '@react-pdf/renderer';
import { Booking } from '../../../../models';
import contractStyles from '../contractStyleSheet';
import LabeledValueView from './LabeledValueView';

interface LocalProps {
    booking?: Booking;
}

const AudienceSectionView = ({ booking }: LocalProps) => {
    return (
        <>
            <Text style={contractStyles.h2}>Audience</Text>
            <LabeledValueView label="Approximate Size" value={booking?.audienceSize} />
            <LabeledValueView label="Approximate Age" value={booking?.audienceAge} />
        </>
    );
};

export default AudienceSectionView;
