import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';

interface LocalProps {
    onClick: () => void;
    icon: IconProp;
    children?: React.ReactNode;
}

const IconButton = ({ onClick, icon, children }: LocalProps) => {
    return (
        <Button variant="secondary" onClick={onClick} size="sm">
            <FontAwesomeIcon icon={icon} size="sm" />
            {children}
        </Button>
    );
};

export default IconButton;
