import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fragment } from 'react';
import { Spinner } from 'react-bootstrap';
import DataTable, { TableColumn } from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { BookingSummary } from '../../../models/bookings';
import AppDate, { appDateSort } from './AppDate';
import BookingStatusBadge from './BookingStatusBadge';
import { AccountName } from './ClientName';
import CurrencyDisplay from './CurrenyDisplay';

interface LocalProps {
    data?: BookingSummary[];
    isLoading: boolean;
}

const BookingSummaryTable = ({ data, isLoading }: LocalProps) => {
    const columns: TableColumn<BookingSummary>[] = [
        {
            name: 'Client',
            cell: (row) => (
                <Link to={`./bookings/${row.id}`}>
                    <AccountName name={row.clientName} isBoces={row.isBoces} />
                </Link>
            ),
            sortable: true,
            selector: (row) => row.clientName,
        },
        {
            name: 'Performer',
            cell: (row) => (
                <>
                    {row.performerNames.map((n) => (
                        <Fragment key={n}>
                            {n}
                            <br />
                        </Fragment>
                    ))}
                </>
            ),
        },
        {
            name: 'Status',
            cell: (row) => <BookingStatusBadge statusId={row.statusTypeId} />,
            maxWidth: '150px',
        },
        {
            name: 'Date',
            center: true,
            cell: (row) => <AppDate date={row.earliestPerformanceDate} />,
            maxWidth: '100px',
            sortFunction: (a, b) => appDateSort(a.earliestPerformanceDate, b.earliestPerformanceDate),
        },
        {
            name: 'Deposit Due',
            center: true,
            cell: (row) => <AppDate date={row.depositDueDate} />,
            maxWidth: '114px',
            sortFunction: (a, b) => appDateSort(a.depositDueDate, b.depositDueDate),
        },
        {
            name: 'Signed?',
            center: true,
            cell: (row) => (row.contractSignedDate ? <FontAwesomeIcon className="text-success" icon={faCheckCircle} /> : <>--</>),
            maxWidth: '80px',
            sortFunction: (a, b) => appDateSort(a.contractSignedDate, b.contractSignedDate),
        },
        {
            name: 'Billable',
            right: true,
            cell: (row) => <CurrencyDisplay value={row.billableAmount} />,
            maxWidth: '114px',
            sortable: true,
            selector: (row) => row.billableAmount,
        },
        {
            name: 'Deposit',
            right: true,
            cell: (row) => <CurrencyDisplay value={row.depositAmount} />,
            maxWidth: '114px',
            sortable: true,
            selector: (row) => row.depositAmount,
        },
        {
            name: 'Paid',
            right: true,
            cell: (row) => <CurrencyDisplay value={row.paidAmount} />,
            maxWidth: '114px',
            sortable: true,
            selector: (row) => row.paidAmount,
        },
        {
            name: 'Due',
            right: true,
            cell: (row) => <CurrencyDisplay value={row.billableAmount - row.paidAmount} />,
            maxWidth: '114px',
            sortable: true,
            selector: (row) => row.paidAmount,
        },
    ];

    return (
        <DataTable
            pagination
            highlightOnHover
            striped
            columns={columns}
            data={data ?? []}
            defaultSortFieldId={3}
            progressPending={isLoading}
            progressComponent={
                <div className="m-5">
                    <Spinner />
                </div>
            }
        />
    );
};

export default BookingSummaryTable;
