import { AddressModel, AzureMapsAddressResult, AzureMapsAddressSerachResult } from '../models';
import ClassActClientBase from './ClassActClientBase';

class MapsClient extends ClassActClientBase {
    addressSearch = async (searchString: string) => {
        const result = await this.fetchJson<AzureMapsAddressSerachResult>(`api/maps/search/address/${searchString}`, 'GET');

        return result ?? [];
    };

    saveAddress = async (address: AzureMapsAddressResult) => {
        const result = await this.fetchJson<AddressModel>(`api/maps`, 'POST', address);

        return result ?? [];
    };
}

export default new MapsClient();
