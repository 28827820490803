import ReactSelect, { FormatOptionLabelMeta } from 'react-select';
import { BookingStatusType } from '../../../models';
import { api } from '../../../store/api';

interface LocalProps {
    bookingId: number;
    statusTypeId: number;
    readonly?: boolean;
}

interface StatusOption {
    value: number;
    label: string;
    status: BookingStatusType;
}

const BookingStatusSelect = ({ bookingId, statusTypeId, readonly }: LocalProps) => {
    const { data: bookingStatusTypes } = api.useGetAllBookingStatusTypesQuery();
    const [setStatus] = api.useUpdateContractStatusMutation();
    const options: StatusOption[] | undefined = bookingStatusTypes?.map((x) => ({
        value: x.id,
        label: x.name,
        status: x,
    }));
    const selectedOption = options?.find((x) => x.status.id === statusTypeId);

    const formatOptionLabel = (data: StatusOption, formatOptionLabelMeta: FormatOptionLabelMeta<StatusOption>) => {
        const style: React.CSSProperties = {
            backgroundColor: `${data.status.calendarColorHex}`,
        };

        return (
            <div className="d-flex">
                <div className="py-2 px-3 m-2 rounded" style={style}></div>
                <h5 className="p-1 m-1">{data.label}</h5>
            </div>
        );
    };

    return (
        <div className="">
            <ReactSelect
                options={options}
                value={selectedOption}
                formatOptionLabel={formatOptionLabel}
                onChange={(e) => e && setStatus({ contractId: bookingId, statusTypeId: e.status.id })}
                isDisabled={readonly}
            />
        </div>
    );
};

export default BookingStatusSelect;
