import { Text, View } from '@react-pdf/renderer';
import { PerformerContractProps } from '../PerformerContractDocument';
import { StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    page: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    h1: {
        color: '#274E96',
        fontSize: 15,
        marginTop: 10,
    },
    h2: {
        color: '#274E96',
        fontSize: 12,
        marginBottom: 5,
        marginTop: 10,
    },
    h3: {
        fontSize: 11,
        marginTop: 10,
    },
    label: {
        color: 'grey',
        fontSize: 8,
        alignSelf: 'flex-end',
    },
    body: {
        fontSize: 10,
        color: '#292929',
    },
    clientColumn: {
        width: '40%',
        marginLeft: 10,
        marginTop: 10,
    },
    performerColumn: {
        width: '60%',
        marginTop: 10,
    },
});

const BodySection = ({ booking, bookingPerformer, performer, performerAuthorizedContact, siteContact }: PerformerContractProps) => {
    const performances = booking?.performances.filter((x) => x.actId === performer.id);

    return (
        <View style={{ ...styles.body, marginHorizontal: 40 }}>
            <View style={{ marginTop: 10, flexDirection: 'row' }}>
                <View>
                    <Text style={{ ...styles.h2 }}>Performer</Text>
                </View>
                <View style={{ marginLeft: 5, marginTop: 11 }}>
                    <Text>{performer.name}</Text>
                </View>
            </View>
            <View style={{ marginTop: 5, flexDirection: 'row' }}>
                <View>
                    <Text style={{ ...styles.h2 }}>Amount Payable to Performer</Text>
                </View>
                <View style={{ marginLeft: 5, marginTop: 11 }}>
                    <Text>${bookingPerformer.amountDue}</Text>
                </View>
            </View>
            <View style={{ marginTop: 5, flexDirection: 'row' }}>
                <View>
                    <Text style={{ ...styles.h2 }}>Authorized Contact</Text>
                </View>
                <View style={{ marginLeft: 5, marginTop: 11 }}>
                    <Text>
                        {performerAuthorizedContact?.firstName} {performerAuthorizedContact?.lastName}
                    </Text>
                    {performerAuthorizedContact?.contactMethods.map((x) => (
                        <Text key={x.id}>
                            {x.value} ({x.name})
                        </Text>
                    ))}
                </View>
            </View>

            <View style={{ marginTop: 5 }}>
                <View>
                    <Text style={{ ...styles.h2 }}>Performances</Text>
                </View>
                <View style={{ marginLeft: 30, marginTop: 5 }}>
                    {performances?.map((x) => (
                        <View key={x.id} style={{ marginBottom: 10 }}>
                            <View style={{ flexDirection: 'row' }}>
                                <View style={{ width: '40%' }}>
                                    <LabelValue label="Date" value={`${x.date.month}/${x.date.day}/${x.date.year}`} labelWidth={80} />
                                    <LabelValue label="Performers" value={x.numberOfPerformers} labelWidth={80} />
                                    <LabelValue label="Show Length" value={x.showLength} labelWidth={80} />
                                </View>
                                <View>
                                    <LabelValue label="Check-in Time" value={x.checkInTime} labelWidth={120} />
                                    <LabelValue label="Performance Time" value={x.performanceTime} labelWidth={120} />
                                </View>
                            </View>
                            <LabelValue label="Location" value={x.venueName} labelWidth={80} />
                            <LabelValue label="Address" value={x.address?.freeformAddress} labelWidth={80} />
                        </View>
                    ))}
                </View>
            </View>
            <View style={{ flexDirection: 'row' }}>
                <View>
                    <Text style={{ ...styles.h2 }}>Site Contact</Text>
                </View>
                <View style={{ marginLeft: 5, marginTop: 11 }}>
                    <Text>
                        {siteContact.firstName} {siteContact.lastName}
                    </Text>
                    {siteContact.contactMethods.map((x) => (
                        <Text key={x.id}>
                            {x.value} ({x.name})
                        </Text>
                    ))}
                </View>
            </View>
            <View style={{ flexDirection: 'row' }}>
                <View>
                    <Text style={{ ...styles.h2 }}>Notes</Text>
                </View>
                <View style={{ marginLeft: 5, marginTop: 11 }}>
                    <Text>{bookingPerformer.notes}</Text>
                </View>
            </View>
            <View style={{ flexDirection: 'row' }}>
                <View>
                    <Text style={{ ...styles.h2 }}>Additional Terms</Text>
                </View>
                <View style={{ marginLeft: 5, marginTop: 11 }}>
                    <Text>{bookingPerformer.additionalTerms}</Text>
                </View>
            </View>
        </View>
    );
};

interface LabelValueProps {
    label: string;
    value?: string;
    labelWidth: number;
}

const LabelValue = ({ label, value, labelWidth }: LabelValueProps) => {
    return (
        <View style={{ flexDirection: 'row' }}>
            <View style={{ width: labelWidth }}>
                <Text>{label}: </Text>
            </View>
            <Text>{value}</Text>
        </View>
    );
};

export default BodySection;
