import ClassActClientBase from './ClassActClientBase';

class ContractsClient extends ClassActClientBase {
    downloadPdf = async (id: number) => {
        const result = await this.fetchBlob(`api/contracts/${id}/download/pdf`, 'GET');

        return result;
    };

    downloadWord = async (id: number) => {
        const result = await this.fetchBlob(`api/contracts/${id}/download/word`, 'GET');

        return result;
    };
}

export default new ContractsClient();
