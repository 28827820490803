import { Col, Form, Row } from 'react-bootstrap';
import { api } from '../../../../store/api';
import { useMemo, useState } from 'react';
import { debounce } from 'lodash';

interface LocalProps {
    accountId: number;
    addressId: number;
    name: string;
}

const EditableAddressName = ({ accountId, addressId, name }: LocalProps) => {
    const [updateName] = api.useUpdateAddressNameMutation();
    const [value, setValue] = useState(name);

    const debouncedUpdate = useMemo(() => debounce((v: string) => updateName({ accountId, addressId, name: v }), 500), [accountId, addressId, updateName]);

    const onChange = (v: string) => {
        setValue(v);
        debouncedUpdate(v);
    };

    return (
        <Form.Group>
            <Row>
                <Form.Label column className="col-md-auto fw-bold">
                    Address Name:
                </Form.Label>
                <Col>
                    <Form.Control type="text" value={value} onChange={(e) => onChange(e.target.value)} className="plain-text-form-control" />
                </Col>
            </Row>
        </Form.Group>
    );
};

export default EditableAddressName;
