import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useEffect, useMemo, useState } from 'react';
import ReactSelect, { SingleValue } from 'react-select';
import { ContactModel } from '../../../models';
import { api } from '../../../store/api';

interface LocalProps {
    contactId?: number;
    onChange: (account: ContactModel) => void;
    contacts?: ContactModel[];
}

interface ContactSelectOption {
    label: string;
    value: number;
    contact: ContactModel;
}

const getOptionText = (contact: ContactModel) => {
    const name = `${contact.firstName} ${contact.lastName}`;

    return contact.title?.length ? `${name} (${contact.title})` : name;
};

const ContactSearchDropDown = ({ contactId, onChange, contacts = [] }: LocalProps) => {
    const { data: contact } = api.useGetContactQuery(contactId ?? skipToken);

    const [selectedOption, setSelectedOption] = useState<ContactSelectOption>();
    const options: ContactSelectOption[] = useMemo(() => {
        return (
            contacts.map((x) => ({
                label: getOptionText(x),
                value: x.id,
                contact: x,
            })) ?? []
        );
    }, [contacts]);

    useEffect(() => {
        if (contact) {
            const selected = options.find((x) => x.value === contact.id);
            setSelectedOption(selected);
        }
    }, [contact, options]);

    const onSelectionChanged = (option: SingleValue<ContactSelectOption>) => {
        if (option) {
            setSelectedOption(option);
            onChange(option.contact);
        }
    };

    return <ReactSelect value={selectedOption} onChange={(x) => onSelectionChanged(x)} options={options} />;
};

export default ContactSearchDropDown;
