import { View } from '@react-pdf/renderer';
import { AccountModel, ContactModel, Booking } from '../../../../models';
import contractStyles from '../contractStyleSheet';
import AudienceSectionView from './AudienceSectionView';
import ClientSectionView from './ClientSectionView';
import VenueSectionView from './VenueSectionView';

interface LocalProps {
    booking?: Booking;
    client?: AccountModel;
    authorizedContact?: ContactModel;
}

const ClientColumnView = ({ booking, client, authorizedContact }: LocalProps) => {
    return (
        <View style={{ ...contractStyles.clientColumn, alignItems: 'flex-start' }}>
            <ClientSectionView {...{ client, authorizedContact }} />
            <VenueSectionView booking={booking} />
            <AudienceSectionView booking={booking} />
        </View>
    );
};

export default ClientColumnView;
