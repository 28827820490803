import { faEdit, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../../../hooks/rtk';
import useActAccountType from '../../../../hooks/useActAccountType';
import { BookingPerformerModel } from '../../../../models';
import { api } from '../../../../store/api';
import { contactMaintenanceActions } from '../../../../store/contactMaintenanceSlice';
import { contractEditPerformerActions, contractEditPerformerSelectors } from '../../../../store/contracts/editSlices/contractEditPerformerSlice';
import ConfirmCancelModal from '../../../shared/components/ConfirmCancelModal';
import ContactSearchDropDown from '../../../shared/components/ContactSearchDropDown';
import IconButton from '../../../shared/components/IconButton';
import SaveCancelButtonRow from '../../../shared/components/SaveCancelButtonRow';
import EntityEditOffcanvas from '../../../shared/EntityEditOffcanvas';
import DeleteButton from '../../../shared/components/DeleteButton';

interface LocalProps {
    contractId: number;
    performer: BookingPerformerModel;
}

const ContractPerformerEditButtonSuite = ({ contractId, performer }: LocalProps) => {
    const dispatch = useAppDispatch();
    const state = useAppSelector((state) => state.contractEditPerformer);
    const accountType = useActAccountType();

    const contactId = state.workingCopy?.authorizedContactId;
    const { data: account } = api.useGetPerformerQuery(performer.actId);
    const [remove] = api.useRemovePerformerMutation();
    const isPendingChanges = useAppSelector(contractEditPerformerSelectors.selectIsPendingChanges());
    const [isEditing, setIsEditing] = useState(false);
    const [confirmCancel, setConfirmCancel] = useState(false);
    const [updatePerformer] = api.useUpdatePerformerMutation();

    const onEditClicked = () => {
        setIsEditing(true);
        dispatch(contractEditPerformerActions.open({ contractId, entity: performer }));
    };

    const cancelClicked = () => {
        if (isPendingChanges) {
            setConfirmCancel(true);
        } else {
            cancel();
        }
    };

    const cancel = () => {
        dispatch(contractEditPerformerActions.close());
        setConfirmCancel(false);
        setIsEditing(false);
    };

    const save = () => {
        if (state.workingCopy) {
            updatePerformer({
                contractId,
                performer: state.workingCopy,
            });
        }
        cancel();
    };

    const removePerformer = () => {
        if (performer.id > 0) {
            remove({ contractId, performerId: performer.id });
            cancel();
        }
    };

    return (
        <>
            <IconButton icon={faEdit} onClick={onEditClicked} />
            <EntityEditOffcanvas title="Edit Performer" show={isEditing} onHide={cancelClicked} isPendingChanges={isPendingChanges}>
                <Row>
                    <Col>
                        <Form.Label>Authorized Contact</Form.Label>
                        <div className="d-flex">
                            <div className="flex-grow-1 mx-2">
                                <ContactSearchDropDown
                                    contactId={contactId}
                                    contacts={account?.contacts}
                                    onChange={(contact) => {
                                        if (state.workingCopy) {
                                            dispatch(contractEditPerformerActions.updateWorkingCopy({ ...state.workingCopy, authorizedContactId: contact.id }));
                                        }
                                    }}
                                />
                            </div>
                            {accountType && (
                                <Button
                                    variant="primary"
                                    onClick={() => dispatch(contactMaintenanceActions.createContact({ accountId: performer.actId, accountType }))}
                                >
                                    <FontAwesomeIcon icon={faPlusCircle} /> New
                                </Button>
                            )}
                        </div>
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col>
                        <Form.Label>To Be Paid to Performer</Form.Label>
                        <Form.Control
                            type="number"
                            value={state.workingCopy?.amountDue ?? ''}
                            onChange={(e) => {
                                if (state.workingCopy) {
                                    dispatch(contractEditPerformerActions.updateWorkingCopy({ ...state.workingCopy, amountDue: +e.target.value }));
                                }
                            }}
                        />
                    </Col>
                    <Col></Col>
                </Row>
                <Row className="my-3">
                    <Col>
                        <Form.Label>Performer Notes</Form.Label>
                        <Form.Control
                            as="textarea"
                            value={state.workingCopy?.notes ?? ''}
                            onChange={(e) => {
                                if (state.workingCopy) {
                                    dispatch(contractEditPerformerActions.updateWorkingCopy({ ...state.workingCopy, notes: e.target.value }));
                                }
                            }}
                        />
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col>
                        <Form.Label>Performer Additional Terms</Form.Label>
                        <Form.Control
                            as="textarea"
                            value={state.workingCopy?.additionalTerms ?? ''}
                            onChange={(e) => {
                                if (state.workingCopy) {
                                    dispatch(contractEditPerformerActions.updateWorkingCopy({ ...state.workingCopy, additionalTerms: e.target.value }));
                                }
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>Payment info</Col>
                </Row>
                <SaveCancelButtonRow
                    className="mt-2 justify-content-end"
                    cancel={cancelClicked}
                    save={save}
                    isPendingChanges={isPendingChanges}
                    deleteButton={
                        performer.id > 0 && (
                            <DeleteButton
                                buttonText="Delete Performer"
                                message="Are you sure you would like to remove this performer?"
                                onConfirm={removePerformer}
                            />
                        )
                    }
                />
            </EntityEditOffcanvas>
            <ConfirmCancelModal show={confirmCancel} cancelAborted={() => setConfirmCancel(false)} cancelConfirmed={cancel} cancelClicked={cancelClicked} />
        </>
    );
};

export default ContractPerformerEditButtonSuite;
