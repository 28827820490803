import { createEntityAdapter, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AzureMapsAddressResult } from '../models';
import { RootState } from './store';
import { mapsClient } from '../api';

const search = createAsyncThunk('address/search', async (seachString: string, thunkAPI) => {
    const response = await mapsClient.addressSearch(seachString);
    return response;
});

const addressSearchAdapter = createEntityAdapter<AzureMapsAddressResult>({
    selectId: (act) => act.id,
    sortComparer: (a, b) => b.score - a.score, // Highest number on top by default.
});

export const addressSearchSlice = createSlice({
    name: 'addressSearch',
    initialState: addressSearchAdapter.getInitialState({
        isLaoding: false,
    }),
    reducers: {
        clear(state) {
            addressSearchAdapter.removeAll(state);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(search.pending, (state, action) => {
            state.isLaoding = true;
        });
        builder.addCase(search.fulfilled, (state, action) => {
            addressSearchAdapter.setAll(state, action.payload.results);
            state.isLaoding = false;
        });
    },
});

const addressSearchSelectors = addressSearchAdapter.getSelectors<RootState>((state) => state.addressSearchResults);
const addressSearchActions = {
    search,
    ...addressSearchSlice.actions,
};

export default addressSearchSlice.reducer;
export { addressSearchSelectors, addressSearchActions };
