import { msalInstance } from '..';
import { classActRequest } from '../authConfig';

abstract class ClassActClientBase {
    baseUrl: string;
    headers: Headers;

    constructor() {
        this.baseUrl = process.env.REACT_APP_API_BASE_URL!;

        this.headers = new Headers();
        this.headers.set('Accept', 'application/json');
        this.headers.set('X-Requested-With', 'XMLHttpRequest');
        this.headers.set('Content-Type', 'application/json:charset=utf-8');
    }

    protected fetchJson = async <T>(url: string, method: 'GET' | 'PUT' | 'POST' | 'DELETE', body?: any): Promise<T> => {
        const response = await this.fetchResponse(url, method, body);

        return response.json();
    };

    protected fetchBlob = async (url: string, method: 'GET' | 'PUT' | 'POST' | 'DELETE', body?: any): Promise<Blob> => {
        const response = await this.fetchResponse(url, method, body);

        return response.blob();
    };

    protected fetchResponse = async (url: string, method: 'GET' | 'PUT' | 'POST' | 'DELETE', body?: any) => {
        const token = await msalInstance
            .acquireTokenSilent({
                ...classActRequest,
                account: msalInstance.getAllAccounts()[0],
            })
            .then((response) => response.accessToken);

        const bearer = `Bearer ${token}`;
        this.headers.set('Authorization', bearer);

        const options: RequestInit = {
            method,
            headers: this.headers,
            body: JSON.stringify(body),
        };

        try {
            const response = await fetch(`${this.baseUrl}/${url}`, options);

            return response;
        } catch (error) {}

        throw new Error('oops');
    };
}

export default ClassActClientBase;
