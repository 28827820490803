import { BookingPerformerEditModel } from '../../../models';
import { RootState } from '../../store';
import createEntityEditSlice, { EntityEditState, createIsPendingChangesSelector } from './createEntityEditSlice';

const slice = createEntityEditSlice({
    name: 'contractEditPerformer',
    initialState: { isEditing: false } as EntityEditState<BookingPerformerEditModel>,
    reducers: {},
});

const selectState = (state: RootState) => state.contractEditPerformer;
const selectIsPendingChanges = () => createIsPendingChangesSelector(selectState);

const contractEditPerformerActions = { ...slice.actions };
const contractEditPerformerSelectors = { selectIsPendingChanges };

export { contractEditPerformerActions, contractEditPerformerSelectors };
export default slice.reducer;
