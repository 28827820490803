import { BookingPerformerModel } from '../../../../models';
import CurrencyDisplay from '../../../shared/components/CurrenyDisplay';

interface LocalProps {
    performer: BookingPerformerModel;
}

const ContractPerformerTermsInfo = ({ performer }: LocalProps) => {
    return (
        <div className="contract-terms-info">
            <table>
                <tbody>
                    <tr>
                        <td className="text-muted">
                            <small>To Be Paid to Performer:</small>
                        </td>
                        <td className="text-end">
                            <CurrencyDisplay value={performer.amountDue} />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default ContractPerformerTermsInfo;
