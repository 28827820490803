import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        fontSize: 10,
        color: '#454545',
        marginHorizontal: 10,
    },
});

const TermsOfAgreement = () => (
    <>
        <View style={styles.row}>
            <Text>{`1. `}</Text>
            <Text>
                Performer agrees not to advertise or otherwise solicit client directly or indirectly and or pass out business cards or contact information.
                Class Act contact information is the sole contact information to be given to anyone at the event.
            </Text>
        </View>
        <View style={styles.row}>
            <Text>{`2. `}</Text>
            <Text>Performer agrees not to consume alcoholic beverages at any time before or following the performance on the performance grounds.</Text>
        </View>
        <View style={styles.row}>
            <Text>{`3. `}</Text>
            <Text>
                Performer agrees that this is a subcontractor arrangement, and agrees to carry own personal and business insurance. This contract is contingent
                upon client signing Class Act contract.
            </Text>
        </View>
        <View style={styles.row}>
            <Text>{`4. `}</Text>
            <Text>
                Business Cards: Class Act business Cards will be provided for this engagement. Artist agrees that ONLY Class Act business cards will be passed
                out at this engagement. No other calendars, email address, web sites, ets will be passed out or displayed at this engagement.
            </Text>
        </View>
        <View style={styles.row}>
            <Text>{`5. `}</Text>
            <Text>ABSOLUTELY NO ADDITIONAL GUESTS ARE ALLOWED TO ACCOMPANY ARTIST INTO THE VENUE.</Text>
        </View>
        <View style={styles.row}>
            <Text>{`6. `}</Text>
            <Text>DO NOT ASK CLIENT FOR PAYMENT. Payment will be remitted through Class Act check following performance.</Text>
        </View>
    </>
);

export default TermsOfAgreement;
