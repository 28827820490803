import { PerformanceModel } from './bookings';

export interface EntityBase {
    id: number;
}

export interface AccountModel {
    id: number;
    name: string;
    accountTypeId: number;
    contacts: ContactModel[];
    isBoces: boolean;
}

export interface AccountAddress {
    id: number;
    addressId: number;
    name: string;
    freeFormAddress: string;
}

export interface TypeBase {
    id: number;
    name: string;
}

export interface BookingStatusType extends TypeBase {
    calendarColorHex: string;
}

export interface AccountType extends TypeBase {
    isAct: boolean;
    isClient: boolean;
}

export interface ContactMethodType extends TypeBase {
    isEmail: boolean;
    isPhone: boolean;
}

export interface ContactModel {
    id: number;
    firstName: string;
    lastName: string;
    title: string;
    contactMethods: ContactMethodModel[];
}

export interface ContactMethodModel {
    id: number;
    name: string;
    value: string;
    contactMethodTypeId: number;
}

export interface CalendarEvent {
    id: number;
    start: Date;
    end: Date;
    title: string;
    description: string;
    allDay: boolean;
    free: boolean;
    color: string;
    clientId: number;
    actId: number;
}

export interface AddressModel {
    id: number;
    freeformAddress: string;
}

export interface AppDateModel {
    year: number;
    month: number;
    day: number;
    hour: number;
    minute: number;
    isDateOnly: boolean;
    isUtc: boolean;
}

const toJsDate = (date: AppDateModel): Date => new Date(date.year, date.month - 1, date.day);
const toAppDate = (date: Date): AppDateModel => ({
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    day: date.getDate(),
    hour: 0,
    minute: 0,
    isDateOnly: true,
    isUtc: false,
});

const getEmptyPerformanceModel = (): PerformanceModel => ({
    id: 0,
    actId: 0,
    date: toAppDate(new Date()),
    venueName: '',
    numberOfPerformers: '',
});

export { toAppDate, getEmptyPerformanceModel, toJsDate };

export type { AzureMapsAddressResult, AzureMapsAddressSerachResult, AzureMapsAddress } from './azureMapsModels';
export type {
    BookingCreateModel,
    Booking,
    PerformanceModel,
    BookingChangeLogModel,
    BookingPerformerModel,
    BookingPerformerEditModel,
    BookingClientEditModel,
    PerformanceEditModel,
    TrackingNoteModel,
} from './bookings';
